import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import AssetsHomeTableItem from './AssetsHomeTableItem';

class AssetsHomeTable extends Component {
  render() {
    return (
      <div className="col-sm-12">
        {/* <p className="info-sys-box mb-5"><Translate id="bonexFeePromo" /></p> */}
        <table className="table-box-style">
          {_.map(this.props.rates || [], (rate) => {
            if (rate.featured && !rate.trade_disabled) {
              return (
                <AssetsHomeTableItem {...rate} key={rate.id} user={this.props.user} config={this.props.config} />
              )
            }
          })}
        </table>
        <div className='table-box-more'>
          <Link to="/spot-trading" className="btn-link-box orange-btn"><Translate id="viewAllCrypto" /></Link>
        </div>
      </div>
    );
  }
};

function mapStateToProps({ user, rates, config }) {
  return { user, rates, config };
}

export default connect(mapStateToProps)(AssetsHomeTable);
