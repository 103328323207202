import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import Loading from '../Loading';

const UserHeader = ({ user }) => {
    const validated = user.validated ? '' : (
        <div className="col-md-12">
            <div className="infoFigureBox p20">
                <h5><Translate id="userMessages.emailNotValidated" /></h5>
            </div>
        </div>
    );

    const verified = user.verified ? (
        <div className="col-lg-6 col-md-6">
            <div className="infoFigureBox green-box">
                <figure>
                    <i className="sprite verified-icon"></i>
                </figure>
                <div className="text">
                    <strong><Translate id="verified" /></strong>
                    <p><Translate id="verified" /> <Translate id="user" /></p>
                    <i className="sprite verified-checked-icon"></i>
                </div>
            </div>
        </div>
    ) : (
        <div className="col-lg-6 col-md-6">
            <div className="infoFigureBox red-box">
                <figure>
                    <i className="sprite nonverified-icon"></i>
                </figure>
                <div className="text">
                    <strong><Translate id="not" /> <Translate id="verified" /></strong>
                    <p><Translate id="not" /> <Translate id="verified" /> <Translate id="user" /></p>
                </div>
            </div>
        </div>
    );

    if(!_.isEmpty(user)) {
        return (
            <div>
                {validated}
                <div className="col-lg-6 col-md-6">
                    <div className="infoFigureBox">
                        <figure>
                            <i className="sprite members-icon"></i>
                        </figure>
                        <div className="text">
                            <strong>{user.nickname}</strong>
                            <p>{user.email}</p>
                        </div>
                    </div>
                </div>
                {verified}
            </div>
        );
    } else {
        return <Loading />
    }
};

function mapStateToProps({ user }) {
    return { user };
}

export default connect(mapStateToProps)(UserHeader);