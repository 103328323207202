import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { fetchUser, fetchUserReferrals } from '../../actions';
import { Translate } from 'react-localize-redux';
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebar from '../../components/users/UserSidebar';
import AccountsBalances from '../../components/accounts/AccountsBalances';
import AffiliateTableNew from '../../components/affiliate/AffiliateTableNew';
import { Link } from 'react-router-dom';

class AffiliatePageNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      referral_counter: {
        level_1: 0,
        level_2: 0,
        level_3: 0
      }
    };
  }



  componentDidMount() {
    this.props.fetchUserReferrals().then(reff => {
      if (reff) {
        this.setState({
          referral_counter: reff
        });
      }
    });
  }

  render() {
    return (
      <div className=" ">
        <h2>AFFILIATE</h2>
        <p> <Translate id="inviteFriends" /></p>
        <div className="affiliate-form-field">
          <label>Link: </label>
          <input
            className='affiliate-input-field'
            type='text'
            disabled
            value={`https://bonex.org/signup/${this.props.user.referral}`}
          />
        </div>
        <div>
          <AffiliateTableNew />
        </div>
      </div>
    );
  }
};

function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default connect(mapStateToProps, { fetchUser, fetchUserReferrals })(
  requireAuth(AffiliatePageNew)
)
