import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { fetchUser } from '../../actions';
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebar from '../../components/users/UserSidebar';
import UserHeader from '../../components/users/UserHeader';
// import AccountsBalances from '../../components/accounts/AccountsBalances';
import OrdersTable from '../../components/orders/OrdersTable';

class OrdersPage extends Component {
  head() {
    return (
      <Helmet>
        <title>{`Поръчки - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`Поръчки - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  componentDidMount() {
    if (typeof window != 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="container">
          <div className="row">
            {this.head()}
            <div className="col-12 col-lg-10 col-md-9 order-cols-right">
              <div className="profile-page-cont profile-cols-height">
                <div className="profile-page-box">
                  <div className="row">
                    {/* <AccountsBalances /> */}
                    <UserHeader />
                    <OrdersTable />
                  </div>
                </div>
              </div>
            </div>
            <UserSidebar current="orders" />
          </div>
      </div>
    );
  }
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default {
  component: connect(mapStateToProps, { fetchUser })(
    requireAuth(OrdersPage)
  ),
  loadData: ({ dispatch }) => dispatch(fetchUser())
}
