import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { fetchAccount, fetchRates } from "../../actions";
import requireAuth from "../../components/hocs/requireAuth";
import UserSidebar from "../../components/users/UserSidebar";
import Account from "../../components/accounts/Account";
import UserSidebarNew from "../../components/users/UserSidebarNew";
import Account2New from "../../components/accounts/Account2New";
import { set } from "lodash";
import AccountOrdersTableNew from "../../components/orders/AccountOrdersTableNew";

class AccountPageNew extends Component {
  constructor(props) {
    super(props);
    this.onBackToAccounts = this.onBackToAccounts.bind(this)
  }
  onBackToAccounts() {
    window.location.replace("/accounts");
  }

  head() {
    return (
      <Helmet>
        <title>{`${this.props.account.type ? this.props.account.type + " " : ""
          } Акаунт - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`${this.props.account.type ? this.props.account.type + " " : ""
            } Акаунт - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAccount(this.props.match.params.type);
    this.props.fetchRates();
  }

  render() {
    return (
      <div className="container">
        <div className="" style={{ padding: "0px 20px" }}>
          {this.head()}
          <UserSidebarNew />
          <div className="">
            <div className="row">
              <div className="">
                <div className="profile-page-new-wrapper">
                  <button type="button" onClick={this.onBackToAccounts} style={{ backgroundColor: "transparent", border: "none" }}>
                    <i className="fal fa-arrow-circle-left" style={{ fontSize: "30px", color: "#257199" }}></i>
                  </button>
                  <Account2New
                    account={this.props.account}
                    rates={this.props.rates}
                    currency={this.props.currency}
                  />
                </div>
                <div className="profile-page-new-wrapper">
                  <h1>Records</h1>
                  <AccountOrdersTableNew></AccountOrdersTableNew>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ account, rates, currency, transactions }) {
  return { account, rates, currency, transactions };
}

export default {
  component: connect(
    mapStateToProps,
    { fetchAccount, fetchRates }
  )(requireAuth(AccountPageNew)),
  loadData: ({ dispatch }) => dispatch(fetchAccount())
};
