import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import AccountActions from "./AccountActions";
import Loading from "../Loading";

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    }
  }

  getRate(assetType, assetAmount) {
    let rate = 0;
    _.map(this.props.rates, (itemRate, i) => {
      let tempRate = parseFloat(itemRate.rate / this.props.currency.rate);
      if (assetType == 'USDT' && this.props.currency.type == 'USDT') {
        rate = parseFloat(assetAmount);
      } else if (itemRate.type == assetType) {
        rate = parseFloat(assetAmount * tempRate);
      }
    });

    return rate.toFixed(2);
  };

  isTradeDisabled(assetType) {
    let result = false;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.trade_disabled;
        return;
      }
    });

    return result;
  };

  render() {
    if (!_.isEmpty(this.props.account)) {

      const trade_disabled = this.isTradeDisabled(this.props.account.type);

      let color = "";
      // if (this.props.account.type == "BTC" || this.props.account.type == "SHIB" || this.props.account.type == "MANA" || this.props.account.type == "LDO") color = "orange";
      // else if (this.props.account.type == "ETH" || this.props.account.type == "COMP") color = "purple";
      // else if (this.props.account.type == "BCH" || this.props.account.type == "PEPE") color = "dark-green";
      // else if (this.props.account.type == "XRP" || this.props.account.type == "MEME" || this.props.account.type == "MATIC") color = "blue";
      // else if (this.props.account.type == "RVN" || this.props.account.type == "SAND" || this.props.account.type == "MASK" || this.props.account.type == "APE" || this.props.account.type == "CAKE") color = "blue-2";
      // else if (this.props.account.type == "ETC" || this.props.account.type == "MKR") color = "light-green";
      // else if (this.props.account.type == "UNI" || this.props.account.type == "AMP") color = "purple-2";
      // else if (this.props.account.type == "LINK" || this.props.account.type == "USDC" || this.props.account.type == "ARB" || this.props.account.type == "SNX") color = "blue-3";
      // else if (this.props.account.type == "AAVE" || this.props.account.type == "CRV") color = "light-oil";

      if (trade_disabled) {
        color = "disabled";
      }

      return (
        <div className="row">
          <div className="col-lg-12">
            <div className={`infoFigureBox crypto-list-box ${color}-box ${this.state.open ? 'open-btn-holder' : ''}`}>
              <figure>
                <Link to={`/accounts/${this.props.account.type}`}>
                  <img
                    src={`/img/assets/${this.props.account.type.toLowerCase()}.png`}
                    alt={this.props.account.type}
                  />
                </Link>
                <Link className="show-hidden-holder" to={'#'} onClick={() => this.setState({ open: !this.state.open })}>
                  <i className="far fa-chevron-down"></i>
                </Link>
              </figure>
              <div className="text">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3">
                    {this.props.activeLanguage && this.props.activeLanguage.code == 'ro' ? (
                      <p>
                        <Translate id="balance" /> {this.props.account.title}
                      </p>
                    ) : (
                      <p>
                        {this.props.account.title} <Translate id="balance" />
                      </p>
                    )}
                    <strong className="crypto-list-title">
                      {parseFloat(this.props.account.balance).toFixed(this.props.account.decimals)}{" "}
                      <span>{this.props.account.type}</span>
                    </strong>
                    <span>
                      {this.getRate(this.props.account.type, this.props.account.balance)}{" "}
                      <Translate id={this.props.currency.symbol} />
                    </span>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5">
                    {!trade_disabled && (
                      <div>
                        {this.props.activeLanguage && this.props.activeLanguage.code == 'ro' ? (
                          <p className="mrg-el-bot-10">
                            <Translate id="address" /> {this.props.account.title} <em>({this.props.account.network})</em>
                          </p>
                        ) : (
                          <p className="mrg-el-bot-10">
                            {this.props.account.title} <Translate id="address" /> <em>({this.props.account.network})</em>
                          </p>
                        )}

                        <p>{this.props.account.identificator}</p>
                        <br />
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="btn-holder-box">
                      <AccountActions account={this.props.account} currency={this.props.currency} trade_disabled={trade_disabled} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
};

export default withLocalize(Account);
