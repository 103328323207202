import _ from 'lodash';
import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { Loader } from '@googlemaps/js-api-loader';
import { fetchAirports, searchAirports, clearAirports, doFlightSearch, doFlightRequest, fetchRates } from "../../actions";
import { JetsCarousel } from './JetsCarousel';
import RequestBookModal from './RequestBookModal';
import { AutocompleteInput } from './AutocompleteInput';

const config = {
  polyLine: {
    strokeOpacity: 0,
    icons: [{
      offset: "100%",
      icon: {
        path: "M 362.985,430.724l-10.248,51.234l62.332,57.969l-3.293,26.145 l-71.345-23.599l-2.001,13.069l-2.057-13.529l-71.278,22.928l-5.762-23.984l64.097-59.271l-8.913-51.359l0.858-114.43 l-21.945-11.338l-189.358,88.76l-1.18-32.262l213.344-180.08l0.875-107.436l7.973-32.005l7.642-12.054l7.377-3.958l9.238,3.65 l6.367,14.925l7.369,30.363v106.375l211.592,182.082l-1.496,32.247l-188.479-90.61l-21.616,10.087l-0.094,115.684",
        scale: 0.05,
        fillColor: "#337ab7",
        fillOpacity: 1,
        strokeColor: "#337ab7",
        strokeWeight: 1
      }
    },
    {
      offset: "0",
      repeat: "20px",
      icon: {
        path: "M 0,-1 0,1",
        strokeColor: "#337ab7",
        strokeOpacity: 1,
        scale: 3
      }
    }]
  }
};

let animTimerId = 0;
function animate(line) {
  var count = 0;
  animTimerId = window.setInterval(function () {
    count = (count + 1) % 200;
    var icons = line.get('icons');
    icons[0].offset = (count / 2) + '%';
    line.set('icons', icons);
  }, 40);
}

class BookFlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flights: [
        {
          from: {},
          to: {},
          date: '',
          hour: '10:00',
          dateReturn: '',
          hourReturn: '20:00',
          passengers: 1
        }
      ],
      type: 'oneway',
      search: [],
      showRequest: false,
      selected: {},
      tomorrow: ''
    };
    this.map;
    this.mapData = {
      flights: [{
        fromMarker: null,
        toMarker: null
      }],
      bounds: null
    };
    this.search = [];
    this.btcRate = 0;
    this.ethRate = 0;
    this.legs = []
  }

  async componentDidMount() {
    this.props.fetchAirports();
    await this.props.fetchRates();
    let btc, eth, usd;
    if (this.props.rates) {
      _.map(this.props.rates, rate => {
        if (rate.type == 'BTC') {
          btc = rate;
        }
        if (rate.type == 'ETH') {
          eth = rate;
        }
        if (rate.type == 'USDT') {
          usd = rate;
        }
      });
      if (btc && usd) {
        this.btcRate = btc.rate / usd.rate;
      }
      if (eth && usd) {
        this.ethRate = eth.rate / usd.rate;
      }
    }

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowStr = tomorrow.getFullYear() + '-' + (tomorrow.getMonth() + 1 < 10 ? '0' + tomorrow.getMonth() + 1 : tomorrow.getMonth() + 1) + '-' + (tomorrow.getDate() < 10 ? '0' + tomorrow.getDate() : tomorrow.getDate());
    this.setState({ tomorrow: tomorrowStr });
    let flights = [...this.state.flights];
    flights[0].date = tomorrowStr;
    flights[0].dateReturn = tomorrowStr;
    this.setState({ flights: [...flights] });

    const loader = new Loader({
      apiKey: "AIzaSyBiQUCXQsEJCFqDmjqwWx_8OBnk-JI4ZX0",
      version: "weekly",
      libraries: ["places", "geometry"]
    });

    loader
      .importLibrary('maps')
      .then(({ Map }) => {
        this.map = new Map(document.getElementById("map"), { zoom: 5, mapTypeControl: false });
        this.map.setCenter(new google.maps.LatLng("45.6484", "17.2268"));
        this.mapData.bounds = new google.maps.LatLngBounds();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentWillUnmount() {
    this.props.clearAirports();
  }

  handleFromOnSelect(id, item) {
    this.map.setCenter(new google.maps.LatLng(item.latitude, item.longitude));
    if (this.mapData.flights[id].fromMarker) {
      this.mapData.flights[id].fromMarker.setMap(null);
    }
    this.mapData.flights[id].fromMarker = new google.maps.Marker({
      position: new google.maps.LatLng(item.latitude, item.longitude),
      map: this.map,
      animation: google.maps.Animation.DROP
    });
    this.legs = [...this.legs, new google.maps.LatLng(item.latitude, item.longitude)];
    if (this.mapData.polyLine) {
      window.clearInterval(animTimerId);
      this.mapData.polyLine.setMap(null);
    }
    this.mapData.polyLine = new google.maps.Polyline({ path: this.getFlightPath(), map: this.map, icons: config.polyLine.icons, strokeOpacity: 0, geodesic: true });
    if (this.legs.length > 1) {
      animate(this.mapData.polyLine);
    }
    this.mapData.bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
    this.map.fitBounds(this.mapData.bounds);
    let flights = [...this.state.flights];
    flights[id].from = item;
    this.setState({ flights: [...flights] });
  }

  handleToOnSelect(id, item) {
    this.map.setCenter(new google.maps.LatLng(item.latitude, item.longitude));
    if (this.mapData.flights[id].toMarker) {
      this.mapData.flights[id].toMarker.setMap(null);
    }
    this.mapData.flights[id].toMarker = new google.maps.Marker({
      position: new google.maps.LatLng(item.latitude, item.longitude),
      map: this.map,
      animation: google.maps.Animation.DROP
    });
    this.legs = [...this.legs, new google.maps.LatLng(item.latitude, item.longitude)];
    if (this.mapData.polyLine) {
      window.clearInterval(animTimerId);
      this.mapData.polyLine.setMap(null);
    }
    this.mapData.polyLine = new google.maps.Polyline({ path: this.getFlightPath(), map: this.map, icons: config.polyLine.icons, strokeOpacity: 0, geodesic: true });

    if (this.legs.length > 1) {
      animate(this.mapData.polyLine);
    }
    this.mapData.bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
    this.map.fitBounds(this.mapData.bounds);
    let flights = [...this.state.flights];
    flights[id].to = item;
    this.setState({ flights: [...flights] });
  }

  getFlightPath() {
    let path = [];
    this.mapData.flights.forEach(flight => {
      if (flight.fromMarker) {
        path = [...path, { lat: flight.fromMarker.getPosition().lat(), lng: flight.fromMarker.getPosition().lng() }];
      }
      if (flight.toMarker) {
        path = [...path, { lat: flight.toMarker.getPosition().lat(), lng: flight.toMarker.getPosition().lng() }];
      }
    })
    return path;
  }

  calcDistanceBetweenPoints(pos1, pos2) {
    var milesCoefficient = 0.621371192;
    var nauticalMilesCoefficient = 0.539956803;
    var distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(pos1, pos2);
    var distanceKilometers = distanceMeters / 1000;
    var ret = { miles: distanceKilometers * milesCoefficient, nauticalMiles: distanceKilometers * nauticalMilesCoefficient, kilometers: distanceKilometers };
    return ret;
  }

  handleDateOnChange(e) {
    let flights = [...this.state.flights];
    flights[e.target.id].date = e.target.value;
    this.setState({ flights: [...flights] });
  }

  handleDateReturnOnChange(e) {
    let flights = [...this.state.flights];
    flights[e.target.id].dateReturn = e.target.value;
    this.setState({ flights: [...flights] });
  }

  handleHourOnChange(e) {
    let flights = [...this.state.flights];
    flights[e.target.id].hour = e.target.value;
    this.setState({ flights: [...flights] });
  }
  handleHourReturnOnChange(e) {
    let flights = [...this.state.flights];
    flights[e.target.id].hourReturn = e.target.value;
    this.setState({ flights: [...flights] });
  }

  handlePassengersOnChange(e) {
    let flights = [...this.state.flights];
    flights[e.target.id].passengers = e.target.value;
    this.setState({ flights: [...flights] });
  }

  handleTypeChange(type) {
    this.setState({ type: type });
  }

  handleAddOnClick(e) {
    let flights = [...this.state.flights];
    flights.push({
      from: {},
      to: {},
      date: '',
      hour: '10:00',
      dateReturn: '',
      hourReturn: '20:00',
      passengers: 1
    });
    this.mapData.flights.push({
      fromMarker: null,
      toMarker: null
    })
    this.setState({ flights: [...flights] });
  }

  handleRemoveOnClick(e) {
    let flights = [...this.state.flights];
    flights.splice(e.target.id, 1);
    this.setState({ flights: [...flights] });
  }

  async handleSearchOnClick(e) {
    if (typeof this.state.flights[0].from.name == 'undefined' &&
      typeof this.state.flights[0].to.name == 'undefined') {
      return;
    }

    let searchData = { ...this.state };
    this.state.flights.forEach((flight, i) => {
      let range;
      let fltmhrs;
      if (this.state.type == 'multicity' && i > 0) {
        range = this.calcDistanceBetweenPoints(new google.maps.LatLng(this.state.flights[i - 1].to.latitude, this.state.flights[i - 1].to.longitude), new google.maps.LatLng(flight.to.latitude, flight.to.longitude));
      } else {
        range = this.calcDistanceBetweenPoints(new google.maps.LatLng(flight.from.latitude, flight.from.longitude), new google.maps.LatLng(flight.to.latitude, flight.to.longitude));
      }
      fltmhrs = (range.kilometers / 800) + 3; //calc average flight time + 3hrs, using avg aircraft speed 800kmh  
      searchData.flights[i].range = range;
      searchData.flights[i].fltmhrs = fltmhrs;
    });

    this.search = await this.props.doFlightSearch(searchData);
    this.setState({ search: this.search });
  }

  onSelectJet(data, curr) {
    this.setState({ selected: data, showRequest: true })
  }

  async onRequest(formData) {
    let request = await this.props.doFlightRequest({ ...this.state, ...formData, search: [], lang: this.props.lang });
  }

  handleSelectedJet() {
    this.setState({ showRequest: false });
  }

  renderSearchResult() {
    if (!this.state.search || !this.state.search.length) return;
    return (
      <div className="row news-list" style={{ paddingBottom: '100px' }}>
        <JetsCarousel slides={this.state.search} btcRate={this.btcRate} ethRate={this.ethRate} onSelect={this.onSelectJet.bind(this)} />
      </div >
    )
  }

  renderForm() {
    if (!this.state.showRequest) return;
    if (this.props.auth && !this.props.auth.authenticated) {
      return <Redirect to="/login" />
    }

    return (
      <RequestBookModal handleHideModal={this.handleSelectedJet.bind(this)} onRequest={this.onRequest.bind(this)} />
    );
  }


  render() {
    return (
      <div className="row" style={{ background: "#FFFFFF", padding: "20px", paddingBottom: '100px', borderRadius: '20px' }}>
        <div className="col-12">
          <div id="map" style={{ width: '100%', height: '600px' }}></div>
        </div>
        <div className="col-12 mb-3">
          <h3><Translate id="requestQuote" /></h3>
          <div className="flight-tabs">
            <button className={(this.state.type == 'oneway') ? "btn btn-rounded btn-action" : "btn btn-rounded btn-secondary"} onClick={() => this.handleTypeChange('oneway')}><Translate id="oneway" /></button>
            <button className={(this.state.type == 'roundtrip') ? "btn btn-rounded btn-action" : "btn btn-rounded btn-secondary"} onClick={() => this.handleTypeChange('roundtrip')}><Translate id="roundtrip" /></button>
            <button className={(this.state.type == 'multicity') ? "btn btn-rounded btn-action" : "btn btn-rounded btn-secondary"} onClick={() => this.handleTypeChange('multicity')}><Translate id="multicity" /></button>
            <button className={(this.state.type == 'emptylegs') ? "btn btn-rounded btn-action" : "btn btn-rounded btn-secondary"} onClick={() => this.handleTypeChange('emptylegs')}><Translate id="emptylegs" /></button>
          </div>
        </div>
        <div className={(this.state.type == 'oneway') ? "col-12 mb-5" : "d-none"}>
          <div className="row flight-search">
            <div className="col-lg-3 mb-2">
              <div className="input-group">
                <span className="input-group-addon" ><span className="glyphicon glyphicon-plane" aria-hidden="true"></span></span>
                {(this.props.airports && this.props.airports.length) ? (
                  <AutocompleteInput
                    options={this.props.airports}
                    onSelect={this.handleFromOnSelect.bind(this)}
                    className="form-control"
                    placeholder="From"
                    id="0"
                  />
                ) : ""}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <div className="input-group">
                <span className="input-group-addon" ><span className="glyphicon glyphicon-plane" aria-hidden="true"></span></span>
                {(this.props.airports && this.props.airports.length) ? (
                  <AutocompleteInput
                    options={this.props.airports}
                    onSelect={this.handleToOnSelect.bind(this)}
                    className="form-control flight-icon"
                    placeholder="To"
                    id="0"
                  />
                ) : ""}
              </div>
            </div>
            <div className="col-lg-2 mb-2">
              <div className="row">
                <div className="col-lg-7 p-lg-0">
                  <input type="date" className="form-control date-input" id="0" min={this.state.tomorrow} onChange={this.handleDateOnChange.bind(this)} value={this.state.flights[0].date} />
                </div>
                <div className="col-lg-5 p-lg-0">
                  <select className="form-control time-input" id="0" onChange={this.handleHourOnChange.bind(this)} value={this.state.flights[0].hour}>
                    {_.map([...Array(24).keys()], h => (
                      <React.Fragment>
                        {_.map(["00", "30"], m => (
                          <option value={`${h}:${m}`}>{`${h}:${m}`}</option>
                        ))}
                      </React.Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-2 mb-2">
              <div className="input-group">
                <span className="input-group-addon" ><span className="glyphicon glyphicon-user" aria-hidden="true"></span></span>
                <input type="number" className="form-control" id="0" onChange={this.handlePassengersOnChange.bind(this)} value={this.state.flights[0].passengers} />
              </div>
            </div>
            <div className="col-lg-2 mb-2">
              <button className="btn btn-action btn-primary btn-rounded" onClick={this.handleSearchOnClick.bind(this)}><i className="fas fa-search"></i> <Translate id="search" /></button>
            </div>
          </div>
        </div>
        <div className={(this.state.type == 'roundtrip') ? "col-12 mb-5" : "d-none"}>
          <div className="row flight-search">
            <div className="col-lg-2 mb-2">
              <div className="input-group">
                <span className="input-group-addon" ><span className="glyphicon glyphicon-plane" aria-hidden="true"></span></span>
                {(this.props.airports && this.props.airports.length) ? (
                  <AutocompleteInput
                    options={this.props.airports}
                    onSelect={this.handleFromOnSelect.bind(this)}
                    id="0"
                    className="form-control"
                    placeholder="From"
                  />
                ) : ""}
              </div>
            </div>
            <div className="col-lg-2 mb-2">
              <div className="input-group">
                <span className="input-group-addon" ><span className="glyphicon glyphicon-plane" aria-hidden="true"></span></span>
                {(this.props.airports && this.props.airports.length) ? (
                  <AutocompleteInput
                    options={this.props.airports}
                    onSelect={this.handleToOnSelect.bind(this)}
                    id="0"
                    className="form-control"
                    placeholder="To" />
                ) : ""}
              </div>
            </div>
            <div className="col-lg-2 mb-2">
              <div className="row">
                <div className="col-lg-7 p-lg-0">
                  <input type="date" className="form-control date-input" id="0" min={this.state.tomorrow} onChange={this.handleDateOnChange.bind(this)} value={this.state.flights[0].date} />
                </div>
                <div className="col-lg-5 pl-md-0">
                  <select className="form-control time-input" id="0" onChange={this.handleHourOnChange.bind(this)} value={this.state.flights[0].hour}>
                    {_.map([...Array(24).keys()], h => (
                      <React.Fragment>
                        {_.map(["00", "30"], m => (
                          <option value={`${h}:${m}`}>{`${h}:${m}`}</option>
                        ))}
                      </React.Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-2 mb-2">
              <div className="row">
                <div className="col-lg-7 p-lg-0">
                  <input type="date" className="form-control date-input" id="0" min={this.state.tomorrow} onChange={this.handleDateReturnOnChange.bind(this)} value={this.state.flights[0].dateReturn} />
                </div>
                <div className="col-lg-5 p-lg-0">
                  <select className="form-control time-input" id="0" onChange={this.handleHourReturnOnChange.bind(this)} value={this.state.flights[0].hourReturn}>
                    {_.map([...Array(24).keys()], h => (
                      <React.Fragment>
                        {_.map(["00", "30"], m => (
                          <option value={`${h}:${m}`}>{`${h}:${m}`}</option>
                        ))}
                      </React.Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="input-group">
                <span className="input-group-addon" ><span className="glyphicon glyphicon-user" aria-hidden="true"></span></span>
                <input type="number" className="form-control" id="0" onChange={this.handlePassengersOnChange.bind(this)} value={this.state.flights[0].passengers} />
              </div>
            </div>
            <div className="col-lg-2">
              <button className="btn btn-action btn-primary btn-rounded" onClick={this.handleSearchOnClick.bind(this)}><i className="fas fa-search"></i> <Translate id="search" /></button>
            </div>
          </div>
        </div>
        <div className={(this.state.type == 'emptylegs') ? "col-12 mb-5" : "d-none"}>
          <p><Translate id="emptylegsDesc" /></p>
          <div className="row flight-search">
            <div className="col-lg-3 mb-2">
              <div className="input-group">
                <span className="input-group-addon" ><span className="glyphicon glyphicon-plane" aria-hidden="true"></span></span>
                {(this.props.airports && this.props.airports.length) ? (
                  <AutocompleteInput
                    options={this.props.airports}
                    onSelect={this.handleFromOnSelect.bind(this)}
                    id="0"
                    className="form-control"
                    placeholder="From"
                  />
                ) : ""}
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <div className="input-group">
                <span className="input-group-addon" ><span className="glyphicon glyphicon-plane" aria-hidden="true"></span></span>
                {(this.props.airports && this.props.airports.length) ? (
                  <AutocompleteInput
                    options={this.props.airports}
                    onSelect={this.handleToOnSelect.bind(this)}
                    id="0"
                    className="form-control"
                    placeholder="To"
                  />
                ) : ""}
              </div>
            </div>
            <div className="col-lg-2 mb-2">
              <div className="row">
                <div className="col-lg-7 p-lg-0">
                  <input type="date" className="form-control date-input" id="0" min={this.state.tomorrow} onChange={this.handleDateOnChange.bind(this)} value={this.state.flights[0].date} />
                </div>
                <div className="col-lg-5 p-lg-0">
                  <select className="form-control time-input" id="0" onChange={this.handleHourOnChange.bind(this)} value={this.state.flights[0].hour}>
                    {_.map([...Array(24).keys()], h => (
                      <React.Fragment>
                        {_.map(["00", "30"], m => (
                          <option value={`${h}:${m}`}>{`${h}:${m}`}</option>
                        ))}
                      </React.Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="input-group">
                <span className="input-group-addon" ><span className="glyphicon glyphicon-user" aria-hidden="true"></span></span>
                <input type="number" className="form-control" id="0" onChange={this.handlePassengersOnChange.bind(this)} value={this.state.flights[0].passengers} />
              </div>
            </div>
            <div className="col-lg-2">
              <button className="btn btn-action btn-primary btn-rounded" onClick={this.handleSearchOnClick.bind(this)}><i className="fas fa-search"></i> <Translate id="search" /></button>
            </div>
          </div>
        </div>
        <div className={(this.state.type == 'multicity') ? "col-12 mb-5" : "d-none"}>
          {_.map(this.state.flights, (flight, i) => (
            <div className="row flight-search">
              <div className="col-lg-3 mb-2">
                <div className="input-group">
                  <span className="input-group-addon" ><span className="glyphicon glyphicon-plane" aria-hidden="true"></span></span>
                  {(this.props.airports && this.props.airports.length) ? (
                    <React.Fragment>
                      {i == 0 ? (
                        <AutocompleteInput
                          options={this.props.airports}
                          onSelect={this.handleFromOnSelect.bind(this)}
                          id={i}
                          className="form-control"
                          placeholder="From"
                          disabled={i > 0}
                        />
                      ) : (
                        <input value={this.state.flights[i - 1].to.name} className="form-control" disabled />
                      )}
                    </React.Fragment>
                  ) : ""}
                </div>
              </div>
              <div className="col-lg-3 mb-2">
                <div className="input-group">
                  <span className="input-group-addon" ><span className="glyphicon glyphicon-plane" aria-hidden="true"></span></span>
                  {(this.props.airports && this.props.airports.length) ? (
                    <AutocompleteInput
                      options={this.props.airports}
                      onSelect={this.handleToOnSelect.bind(this)}
                      id={i}
                      className="form-control"
                      placeholder="To"
                    />
                  ) : ""}
                </div>
              </div>
              <div className="col-lg-2 mb-2">
                <div className="row">
                  <div className="col-lg-7 p-lg-0">
                    <input type="date" className="form-control date-input" id={i} min={this.state.tomorrow} onChange={this.handleDateOnChange.bind(this)} value={this.state.flights[i].date} />
                  </div>
                  <div className="col-lg-5 p-lg-0">
                    <select className="form-control time-input" id={i} onChange={this.handleHourOnChange.bind(this)} value={this.state.flights[i].hour}>
                      {_.map([...Array(24).keys()], h => (
                        <React.Fragment>
                          {_.map(["00", "30"], m => (
                            <option value={`${h}:${m}`}>{`${h}:${m}`}</option>
                          ))}
                        </React.Fragment>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <span className="input-group-addon" ><span className="glyphicon glyphicon-user" aria-hidden="true"></span></span>
                  <input type="number" className="form-control" id={i} onChange={this.handlePassengersOnChange.bind(this)} value={this.state.flights[i].passengers} />
                </div>
              </div>
              <div className="col-lg-1">
              </div>
              {(i != 0) ? (
                <div className="col-lg-1 mb-2">
                  <button className="btn btn-action btn-primary btn-rounded" id={i} onClick={this.handleRemoveOnClick.bind(this)}><i className="fas fa-minus"></i></button>
                </div>
              ) : (
                <div className="col-lg-1 mb-2">
                </div>
              )}
            </div>
          ))}
          <div className="row mt-2">
            <div className="col-lg-4">
            </div>
            <div className="col-lg-3 text-center mb-2">
              <button className="btn btn-action btn-primary btn-rounded w-100 m-0" onClick={this.handleSearchOnClick.bind(this)}><i className="fas fa-search"></i> <Translate id="search" /></button>
            </div>
            <div className="col-lg-4">
            </div>
            <div className="col-lg-1">
              <button className="btn btn-action btn-primary btn-rounded w-100 m-0" onClick={this.handleAddOnClick.bind(this)}><i className="fas fa-plus"></i></button>
            </div>
          </div>
        </div>
        {this.renderSearchResult()}
        {this.renderForm()}
      </div >
    )
  }
}

function mapStateToProps({ user, auth, currency, rates, airports }) {
  return { user, auth, currency, rates, airports };
}

export default connect(
  mapStateToProps,
  { fetchAirports, searchAirports, clearAirports, doFlightSearch, doFlightRequest, fetchRates }
)(BookFlight);
