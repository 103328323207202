import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Translate } from "react-localize-redux";
import { fetchUser } from '../../actions';
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebar from '../../components/users/UserSidebar';
import GeneralSettingsForm from '../../components/settings/GeneralSettingsForm';
import SecuritySettingsForm from '../../components/settings/SecuritySettingsForm';
import PasswordSettingsForm from '../../components/settings/PasswordSettingsForm';
import PrivacySettingsForm from '../../components/settings/PrivacySettingsForm';
import UserHeader from '../../components/users/UserHeader';

class SettingsPage extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Настройки - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Настройки - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidMount() {
        this.props.fetchUser();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.head()}
                    <div className="col-12 col-lg-10 col-md-9 order-cols-right">
                        <div className="profile-page-cont profile-cols-height">
                            <div className="profile-page-box">
                                <div className="row">
                                    <UserHeader />
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="portlet-title tabbable-line tabs-separate">
                                            <span>Select</span>
                                            <ul className="nav nav-tabs" id="form-tabs">
                                                <li className="active">
                                                    <a href="#security" data-toggle="tab"><Translate id="security" /></a>
                                                </li>
                                                <li>
                                                    <a href="#general" data-toggle="tab"><Translate id="general" /></a>
                                                </li>
                                                <li>
                                                    <a href="#password" data-toggle="tab"><Translate id="changePassword" /></a>
                                                </li>
                                                <li>
                                                    <a href="#privacy" data-toggle="tab"><Translate id="privacyRights" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="white-box no-pad">
                                            <div className="portlet-body">
                                                <div className="tab-content">
                                                    <div className="tab-pane fade in active" id="security">
                                                        <SecuritySettingsForm />
                                                    </div>
                                                    <div className="tab-pane fade in" id="general">
                                                        <GeneralSettingsForm />
                                                    </div>
                                                    <div className="tab-pane fade in" id="password">
                                                        <PasswordSettingsForm />
                                                    </div>
                                                    <div className="tab-pane fade in" id="privacy">
                                                        <PrivacySettingsForm />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <UserSidebar current="settings" />
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth }) {
    return { auth };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(
        requireAuth(SettingsPage)
    ),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
