import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Translate } from "react-localize-redux";
import { updatePasswordSettings } from '../../actions';
import { globalRegExes } from '../../utilities/RegEx';

const FIELDS = {
    password: {
        name: 'password',
        type: 'password',
        label: 'password',
        required: true,
        errorMsg: 'passwordIsInvalid'
    },
    passwordNew: {
        name: 'passwordNew',
        type: 'password',
        label: 'passwordNew',
        required: true,
        errorMsg: 'passwordIsInvalid'
    },
    passwordNewConfirm: {
        name: 'passwordNewConfirm',
        type: 'password',
        label: 'passwordConfirm',
        required: true,
        errorMsg: 'passwordConfirmIsInvalid'
    }
};

class PasswordSettingsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            error: ''
        };
    }

    renderField(field) {
        const { input, label, type, meta: { touched, error } } = field;
        const className = `col-md-6 form-group ${touched && error ? 'has-error' : 'has-success'}`;

        return (
            <div className={className}>
                <div className="input-icon">
                    <div className="label-animated has-content">
                        <input 
                            {...input}
                            type={type}
                            className="form-control placeholder-no-fix" 
                            autoComplete="off"
                        />
                        <label className="control-label visible-ie8 visible-ie9"><Translate id={label} /></label>
                        {touched && error ? (
                            <div className="help-block with-errors" style={{marginLeft: '16px'}}>
                                    <span><Translate id={`errors.${error}`} /></span>
                            </div> 
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }

    onSubmit(values) {
        this.props.updatePasswordSettings(values).then((resp) => {
            if(resp.success) {
                this.setState({ 
                    submitted: true 
                });
            } else {
                this.setState({ 
                    error: resp.error 
                });
            }
        });
    }

    render() {
        const { handleSubmit } = this.props;

        if(this.state.submitted) {
            return (
                <div className="infoFigureBox">
                    <div className="row">
                        <div className="col-12">
                            <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                                <h3><Translate id="successLabel" />!</h3>
                            </header>
                        </div>
                        <div className="col-md-offset-2 col-md-8">
                            <div className="success-msg-box">
                                <figure><i className="far fa-check-circle"></i></figure>
                                <h5><Translate id="success.settings" /></h5>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="infoFigureBox">
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <div className="row">
                            <div className="col-md-12 element-section-title has-bgr-element text-center crypto-sell-color" style={{ padding: '0 50px 46px'}}>
                                <h3><Translate id="changePassword" /></h3>
                            </div>
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <p><Translate id="insertOldPassword" /></p>
                                    <hr />
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Field name={FIELDS.password.name} component={this.renderField} { ...FIELDS.password } key={FIELDS.password.name} />
                            </div>
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <p><Translate id="insertNewPassword" /></p>
                                    <hr />
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Field name={FIELDS.passwordNew.name} component={this.renderField} { ...FIELDS.passwordNew } key={FIELDS.passwordNew.name} />
                                <Field name={FIELDS.passwordNewConfirm.name} component={this.renderField} { ...FIELDS.passwordNewConfirm } key={FIELDS.passwordNewConfirm.name} />
                            </div>
                        </div>
                        {this.state.error != '' &&
                            <div className="alert alert-danger">
                                <strong><Translate id="oops" />!</strong> <Translate id={`errors.${this.state.error}`} />
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-10"></div>
                            <div className="col-md-2">
                                <div className="col align-self-end">
                                    <button type="submit"><Translate id="save" /></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>          
            );
        }
    }
};

function validate(formProps) {
    const errors = {};

    _.each(FIELDS, (field, fieldName) => {
        if (!formProps[fieldName] && field.required) {
            errors[fieldName] = field.errorMsg;
        } else {
            if(fieldName == 'password' || fieldName == 'passwordNew') {
                if(formProps[fieldName].length < 8) {
                    errors[fieldName] = 'passwordIsTooShort';
                } else if(formProps[fieldName].length > 36) {
                    errors[fieldName] = 'passwordIsTooLong';
                } else if(!formProps[fieldName].match(globalRegExes.password)) {
                    errors[fieldName] = field.errorMsg;
                }
            }
        }
    });

    if (formProps.passwordNew != '' && 
        formProps.passwordNewConfirm != '' && 
        formProps.passwordNew != formProps.passwordNewConfirm
    ) {
        errors.passwordNewConfirm = 'passwordConfirmIsInvalid';
    }

    return errors;
}

function mapStateToProps({ user }) {
    return {
        user
    };
}

export default connect(mapStateToProps, { updatePasswordSettings })(
    reduxForm({
        validate,
        form: 'updatePasswordSettingsForm',
        fields: FIELDS,
        enableReinitialize : true
    })(PasswordSettingsForm)
)