import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from "react-localize-redux";
import { fetchBonuses } from '../../actions';

class Bonus extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchBonuses();
  }

  componentDidUpdate() {
    const dataEvent = document.createEvent('HTMLEvents');
    dataEvent.initEvent('datatable-data', true, true);
    document.dispatchEvent(dataEvent);
  }

  rows() {
    return _.map(this.props.bonuses, (transaction, number) => {
      return (
        <tr key={transaction.id}>
          <td align="center">
            {number * 1 + 1}
          </td>
          <td>
            <time>
              {new Intl.DateTimeFormat(this.props.translate("lang"), {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(transaction._created))}
            </time>
          </td>
          <td>
            <strong className="amount">{parseFloat(transaction.amount).toFixed(2)} USDT</strong>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="white-box no-pad">
        <div className="table-box">
          <h4 className="form-section-title">
            <Translate id="history" />
            <i className="sprite transfers-icon"></i>
          </h4>
          <table className="custom-table" style={{ width: '100%', overflow: 'scroll' }}>
            <thead>
              <tr>
                <th align="center">
                  <strong>#</strong>
                </th>
                <th align="center">
                  <strong><Translate id="date" /></strong>
                </th>
                <th>
                  <strong><Translate id="bonus" /></strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.rows()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};


function mapStateToProps({ bonuses }) {
  return { bonuses };
}

export default connect(mapStateToProps, { fetchBonuses })(withLocalize(Bonus));