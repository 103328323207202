import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { withLocalize, Translate } from "react-localize-redux";
import { Redirect } from "react-router-dom";
import { fetchUser, fetchAccount } from "../../actions";
import requireAuth from "../../components/hocs/requireAuth";
import UserSidebarNew from "../../components/users/UserSidebarNew";
import AccountBuyFormNew from "../../components/accounts/AccountBuyFormNew";
import AssetLightWidget from "../../components/assets/AssetLightWidget";

class AccountOTCBuyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: "bank",
      txPaymentMethod: false,
    };

    this.onPaymentMethodChange = this.onPaymentMethodChange.bind(this);
    this.onBackToAccounts = this.onBackToAccounts.bind(this)
  }

  onPaymentMethodChange(paymentMethod) {
    this.setState({ paymentMethod });
  }

  onBackToAccounts() {
    window.location.replace("/accounts");
  }

  // setPaymentMethod(paymentMethod) {
  //   this.setState({ paymentMethod });
  // }

  head() {
    if (this.props.user && Object.keys(this.props.user).length !== 0 && !this.props.user.verified) {
      return <Redirect to="/verification" />;
    }

    return (
      <Helmet>
        <title>{`Купи ${this.props.account.type ? this.props.account.type + " " : ""
          } - BoneX | Crypto Exchange`}</title>
        <meta
          property="og:title"
          content={`Купи ${this.props.account.type ? this.props.account.type + " " : ""
            } - BoneX | Crypto Exchange`}
        />
      </Helmet>
    );
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAccount(this.props.match.params.type);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!_.isEmpty(props.transaction)) {
      let txData = JSON.parse(props.transaction.data);
      this.setState({
        txPaymentMethod: txData.paymentMethod,
      });
    }

  }

  componentDidMount() {
    // console.log(this.props.config);
  }

  isTradeDisabled(assetType) {
    let result = false;
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.trade_disabled;
        return;
      }
    });

    return result;
  };

  render() {
    if (!_.isEmpty(this.props.currency) && !_.isEmpty(this.props.account) &&
      this.props.currency.type == "USDT" && this.props.account.type == "USDT") {
      return <Redirect to="/accounts" />;
    }

    return (
      <div className="container">
        <div className="row">
          {this.head()}
          <UserSidebarNew />
          <div className="container">
            <div className="profile-page-new-wrapper" style={{ borderRadius: "20px" }}>
              <div className="profile-page-box">
                <button type="button" onClick={this.onBackToAccounts} style={{ backgroundColor: "transparent", border: "none" }}>
                  <i className="fal fa-arrow-circle-left" style={{ fontSize: "30px", color: "#257199" }}></i>
                </button>
                {!this.isTradeDisabled(this.props.account.type) && (
                  <div className="row">
                    <div className="col-md-12">
                      {_.map(this.props.rates, rate => {
                        if (rate.type == this.props.account.type) {
                          return <AssetLightWidget {...rate} key={rate.id} />;
                        }
                      })}
                      <AccountBuyFormNew
                        account={this.props.account}
                        action={this.onPaymentMethodChange}
                        payment={this.state.paymentMethod}
                        activeLanguage={this.props.activeLanguage}
                      />
                    </div>

                    <div className="col-md-12">
                      <div
                        className="white-box transparent-box-new no-pad new-style-tabs"
                        style={{ marginTop: "0px" }}
                      >
                        <div className="portlet-title tabbable-line">
                          <span>Select</span>
                          <ul className="nav nav-tabs" id="form-tabs">
                            {(this.state.txPaymentMethod == "bank" || !this.state.txPaymentMethod) &&
                              (this.props.currency.type !== "USDT" || this.props.account.type === "USDT") ? (
                              <li
                                className={`${this.state.paymentMethod == "bank" ? "active" : ""
                                  }`}
                              // onClick={this.setPaymentMethod.bind(this, "bank")}
                              >
                                <a href="#bank" data-toggle="tab">
                                  <img src="/img/bank.png" /><br />
                                  <Translate id="bankTransfer" />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {(
                              (this.state.txPaymentMethod == "easypay" || !this.state.txPaymentMethod) &&
                              this.props.currency.type !== "EUR" && this.props.activeLanguage.code != 'ro' && this.props.currency.type !== "RON" &&
                              (this.props.currency.type !== "USDT" || this.props.account.type === "USDT")
                            ) ? (
                              <li
                                className={`${this.state.paymentMethod == "easypay"
                                  ? "active"
                                  : ""
                                  }`}
                              // onClick={this.setPaymentMethod.bind(
                              //   this,
                              //   "easypay"
                              // )}
                              >
                                <a href="#easypay" data-toggle="tab">
                                  <img src="/img/easy-pay.png" /><br />
                                  EasyPay
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {(this.state.txPaymentMethod == "phyre" || !this.state.txPaymentMethod) &&
                              this.props.currency.type !== "EUR" && this.props.currency.type !== "RON" &&
                              (this.props.currency.type !== "USDT" || this.props.account.type === "USDT") ? (
                              <li
                                className={`${this.state.paymentMethod == "phyre"
                                  ? "active"
                                  : ""
                                  }`}
                              // onClick={this.setPaymentMethod.bind(
                              //   this,
                              //   "phyre"
                              // )}
                              >
                                <a href="#phyre" data-toggle="tab">
                                  <img src="/img/phyre.png" style={{ 'width': '100px' }} /><br />
                                  <Translate id="digitalWallet" />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {((this.state.txPaymentMethod == "usdt" || !this.state.txPaymentMethod) && (this.props.account.type !== "USDT")) ? (
                              <li
                                className={`${this.state.paymentMethod == "usdt"
                                  ? "active"
                                  : ""
                                  }`}
                              // onClick={this.setPaymentMethod.bind(
                              //   this,
                              //   "usdt"
                              // )}
                              >
                                <a href="#usdt" data-toggle="tab">
                                  <img src="/img/assets/usdt.png" style={{ 'width': '40px' }} /><br />
                                  <Translate id="payments.usdt" />
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                        <div className="portlet-body">
                          <div className="tab-content">
                            {(this.state.txPaymentMethod == "bank" ||
                              !this.state.txPaymentMethod) &&
                              (this.props.currency.type !== "USDT" || this.props.account.type === "USDT") ? (
                              <div
                                className={`tab-pane fade in ${this.state.paymentMethod == "bank" ? "active" : ""
                                  }`}
                                id="bank"
                              >
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="payment-text">
                                      <p>
                                        <strong>
                                          <Translate id="additionalInfo" />:
                                        </strong>
                                        {(this.state.paymentMethod == "easypay" || this.state.paymentMethod == "bank") ? (
                                          <div style={{ marginBottom: '20px' }}>
                                            <Translate id="easyPayTexts.pt1" />
                                            <br />
                                            <Translate id="easyPayTexts.pt2" />
                                            <br />
                                          </div>
                                        ) : ""}
                                      </p>
                                    </div>
                                  </div>
                                  {!_.isEmpty(this.props.transaction) ? (
                                    <div>
                                      <div className="col-lg-8">
                                        <div className="payment-text">
                                          <p>
                                            <Translate id="toBuy2_1" />{" "}
                                            <strong>{this.props.account.type}</strong>',{" "}
                                            <Translate id="toBuy2_2" />{" "}
                                          </p>
                                          <p><Translate id="bankIban" />:</p>
                                          {(this.props.activeLanguage.code != 'bg') ? (
                                            <div>
                                              <p><Translate id="bankForName" />: BoneX Ltd</p>
                                              <p>IBAN: BG32 ESPY 4004 0052 9457 57</p>
                                            </div>
                                          ) : (
                                            <div>
                                              <p><Translate id="bankForName" />: БОНЕККС</p>
                                              <p>IBAN: BG32 ESPY 4004 0052 9457 57</p>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-4">
                                        <div className="ref-number-box">
                                          <figure>
                                            <i className="far fa-university"></i>
                                          </figure>
                                          <h5>
                                            <Translate id="referenceNumber" />:
                                          </h5>
                                          <strong>
                                            {this.props.transaction.reference}
                                          </strong>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-lg-12">
                                    <p>
                                      <Translate id="bankTax" />
                                    </p>
                                    <p>
                                      <Translate id="thePriceIsIndicative" />{" "}
                                      <Translate id="txFees1" />{" "}
                                      {this.props.account.title}{" "}
                                      <Translate id="txFees2" /> {this.props.config['tx_tax_' + this.props.account.type]} {" "}
                                      {this.props.account.type},{" "}
                                      <Translate id="txFees3" />.
                                    </p>
                                    <p>
                                      <Translate id="moreInfoAfterAction" />
                                    </p>
                                    <p>
                                      <Translate id="priceCanBeDifferent" />
                                    </p>
                                    <p>
                                      <strong>
                                        <Translate id="buyBankContact" />{" "}<a href="mailto:info@bonex.org">info@bonex.org</a>
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {((
                              (this.state.txPaymentMethod == "easypay" || !this.state.txPaymentMethod) &&
                              this.props.currency.type !== "EUR" && this.props.activeLanguage.code != 'ro' && this.props.currency.type !== "RON") &&
                              (this.props.currency.type !== "USDT" || this.props.account.type === "USDT")
                            ) ? (
                              <div
                                className={`tab-pane fade in ${this.state.paymentMethod == "easypay"
                                  ? "active"
                                  : ""
                                  }`}
                                id="easypay"
                              >
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="payment-text">
                                      <p>
                                        <Translate id="toBuy" />{" "}
                                        <strong>{this.props.account.type}</strong>,{" "}
                                        <Translate id="makeMoneyTransfer" />{" "}
                                        <strong>EasyPay</strong>:
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    <div className="payment-text">
                                      <p>
                                        <strong>
                                          <Translate id="additionalInfo" />:
                                        </strong>
                                        {(this.state.paymentMethod == "easypay" || this.state.paymentMethod == "bank") ? (
                                          <div style={{ marginBottom: '20px' }}>
                                            <Translate id="easyPayTexts.pt1" />
                                            <br />
                                            <Translate id="easyPayTexts.pt2" />
                                            <br />
                                          </div>
                                        ) : ""}
                                      </p>
                                    </div>
                                  </div>
                                  {!_.isEmpty(this.props.transaction) ? (
                                    <div className="col-lg-4">
                                      <div className="ref-number-box">
                                        <figure>
                                          <p>
                                            <img
                                              src="/img/easypay_big.png"
                                              style={{ width: "60px" }}
                                            />
                                          </p>
                                        </figure>
                                        <h5>
                                          <Translate id="referenceNumber" />:
                                        </h5>
                                        <strong>
                                          {this.props.transaction.idn}
                                        </strong>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-lg-12">
                                    <p>
                                      <Translate id="thePriceIsIndicative" />{" "}
                                      <Translate id="txFees1" />{" "}
                                      {this.props.account.title}{" "}
                                      <Translate id="txFees2" /> {this.props.config['tx_tax_' + this.props.account.type]} {" "}
                                      {this.props.account.type},{" "}
                                      <Translate id="txFees3" />.
                                    </p>
                                    <p>
                                      <Translate id="moreInfoAfterAction" />
                                    </p>
                                    <p>
                                      <Translate id="priceCanBeDifferent" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {(
                              (this.state.txPaymentMethod == "phyre" || !this.state.txPaymentMethod) &&
                              this.props.currency.type !== "EUR" && this.props.currency.type !== "RON" &&
                              (this.props.currency.type !== "USDT" || this.props.account.type === "USDT")
                            ) ? (
                              <div
                                className={`tab-pane fade in ${this.state.paymentMethod == "phyre"
                                  ? "active"
                                  : ""
                                  }`}
                                id="phyre"
                              >
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="payment-text">
                                      <p>
                                        <Translate id="toBuy" />{" "}
                                        <strong>{this.props.account.type}</strong>,{" "}
                                        <Translate id="withDigitalWallet" />
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    <div className="payment-text">
                                      <p>

                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {((this.state.txPaymentMethod == "usdt" || !this.state.txPaymentMethod) && (this.props.account.type !== "USDT")) ? (
                              <div
                                className={`tab-pane fade in ${this.state.paymentMethod == "usdt"
                                  ? "active"
                                  : ""
                                  }`}
                                id="usdt"
                              >
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="payment-text">
                                      <p>
                                        <Translate id="toBuy" />{" "}
                                        <strong>{this.props.account.type}</strong>,{" "}
                                        <Translate id="payments.usdt" />
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <p>
                                      <Translate id="thePriceIsIndicative" />
                                    </p>
                                    <p>
                                      <Translate id="moreInfoAfterAction" />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <p><strong><Translate id="buySellDisclaimer" /></strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user, account, rates, transaction, config, currency }) {
  return { user, account, rates, transaction, config, currency };
}

export default {
  component: connect(mapStateToProps, { fetchUser, fetchAccount })(
    requireAuth(withLocalize(AccountOTCBuyPage))
  ),
  loadData: ({ dispatch }) => dispatch(fetchUser()),
};
