import _ from "lodash";
import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import ReactDOM from "react-dom";
import { Translate } from "react-localize-redux";

class CommissionPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };
  }

  componentDidMount() {
 
  }

  render() {
    return (
      <div className="commission-modal-wrapper">
        <div className="commission-modal">
          <div className="commission-modal-card">
            <div className="modal-card-text">
              <button type="button" onClick={this.props.onClosePopupClick}>
                x
              </button>
              <strong className="fire-icon-modal" style={{ fontSize: "65px" }}>
                🔥 0%{" "}
              </strong>
              <strong className="heading-text-modal">
                {this.props.activeLanguage.code === "en"
                  ? "Commission on Crypto Purchases!"
                  : "Комисионна за покупка на крипто!"}
              </strong>
              {this.props.activeLanguage.code === "en" ? (
                <div
                  className="deadline-modal"
                  style={{ marginTop: "55px", fontSize: "28px" }}
                >
                  Until October 15th
                </div>
              ) : (
                <div
                  className="deadline-modal"
                  style={{ marginTop: "25px", fontSize: "28px" }}
                >
                  До 15-ти Октомври
                </div>
              )}
              {this.props.activeLanguage.code === "en" ? (
                <div
                  className="final-text-modal"
                  style={{
                    fontSize: "40px",
                    fontWeight: "600",
                    marginTop: "41px",
                  }}
                >
                  "Don’t miss out!
                </div>
              ) : (
                <div
                  className="final-text-modal"
                  style={{
                    fontSize: "40px",
                    fontWeight: "600",
                    marginTop: "21px",
                  }}
                >
                  Не пропускайте!
                </div>
              )}
            </div>
            {/* <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title"><Translate id="request" /></h4>
            </div> */}
            {/* <div className="modal-body">
              {(!this.state.sent) ? (
                <form onSubmit={this.handleOnSubmit.bind(this)}>
                  <div className='row mt-2'>
                    <div className='col-md-12'>
                      <div className="mb-2">
                        <input type="text" className="form-control" placeholder="First Name" required onChange={this.handleFNameOnChange.bind(this)} value={this.state.first_name} />
                      </div>
                      <div className="mb-2">
                        <input type="text" className="form-control" placeholder="Last Name" required onChange={this.handleLNameOnChange.bind(this)} value={this.state.last_name} />
                      </div>
                      <div className="mb-2">
                        <input type="email" className="form-control" placeholder="Email" required onChange={this.handleEmailOnChange.bind(this)} value={this.state.email} />
                      </div>
                      <div className="mb-2">
                        <input type="phone" className="form-control" placeholder="Phone" required onChange={this.handlePhoneOnChange.bind(this)} value={this.state.phone} />
                      </div>
                      <div className="mb-2">
                        <textarea className="form-control" rows="5" cols="10" placeholder="Dates, location, any other preferences" onChange={this.handleAddsOnChange.bind(this)} value={this.state.adds} />
                      </div>
                      <div className="mb-2 text-center">
                        <button type="submit" className='btn btn-rounded'><Translate id="requestBook" /></button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close"><Translate id="cancel" /></button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="text-center" style={{ padding: '30px' }}>
                  <h3>Thank you!<br />Your inquiry is received and you will be contact you shortly.</h3>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(CommissionPopup);
