import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { fetchUser, fetchUserBank, fetchIbans, fetchIbanBalance, fetchIbanTransactions } from '../../actions';
import { Translate, withLocalize } from "react-localize-redux";
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebar from '../../components/users/UserSidebar';
import { Link } from 'react-router-dom';

class BankPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIban: false,
      ibans: [],
      balance: {},
      transactions: {}
    };
  }

  head() {
    return (
      <Helmet>
        <title>{`Open banking - BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`Open banking - BoneX | Crypto Exchange`} />
      </Helmet>
    );
  }

  loadBanksScreen(irisHash) {
    var e = document.createElement("irispay-component");
    this.attachBanksListeners(e);
    e.setAttribute("type", "add-iban");
    e.setAttribute("userhash", irisHash);
    e.setAttribute("backend", "https://developer.irispay.bg/");
    e.setAttribute("show_bank_selector", "true");
    e.setAttribute("lang", this.props.activeLanguage.code); // set language if needed

    let body = document.querySelector("#bank");
    body.appendChild(e);
  }

  attachBanksListeners(e) {
    e.addEventListener("error", (err) => {
      console.log(err);
    });
    e.addEventListener("onlaststep", (data) => {
      console.log(data);
    });
  }

  loadPaymentScreen(irisHash) {
    var e = document.createElement("irispay-component");
    this.attachPaymentListeners(e);
    e.id = "custom-element";
    e.setAttribute("type", "payment");
    e.setAttribute("userhash", irisHash);
    e.setAttribute("backend", "https://developer.irispay.bg/");
    e.setAttribute("lang", this.props.activeLanguage.code);
    // e.setAttribute("country", "bulgaria"); //"romania" //"greece"

    let body = document.querySelector("#transfer")
    body.appendChild(e);
  }

  attachPaymentListeners(e) {
    e.addEventListener("error", (err) => {
      console.log(err);
    });
    e.addEventListener("onlaststep", (data) => {
      console.log(data);
    });
  }

  async componentDidMount() {
    this.props.fetchUserBank().then(async (resp) => {
      this.loadBanksScreen(resp.userHash);
      this.loadPaymentScreen(resp.userHash);
      let ibans = await this.props.fetchIbans();
      this.setState({ ibans });
    });
  }

  async getIbanData(ibanId) {
    this.setState({ selectedIban: ibanId, balance: {}, transactions: {} });

    let balance = await this.props.fetchIbanBalance(ibanId);
    this.setState({ balance });

    let transactions = await this.props.fetchIbanTransactions(ibanId);
    this.setState({ transactions });
  }

  renderIbans() {
    return _.map(this.state.ibans, iban => {

      if (iban && iban.iban && iban.consents && iban.consents.consents[0] && iban.consents.consents[0].status == 'Authorised') {
        return (
          <div className="bank-detail-acc" key={iban.id}>
            <div className='links-out'>
              <p><img src={`https://developer.irispay.bg${iban.liteLogoUrl}`} /></p>
              {/* <p><strong>Банка: </strong> {iban.bankName}</p> */}
              <p><strong>IBAN: </strong> {iban.iban}</p>
              <p><strong><Translate id="ownerName" />: </strong> {iban.ownerName}</p>
              <a className="btn-link xs reverse" title="" onClick={() => this.getIbanData(iban.id)}><Translate id="checkBalance" /></a>
            </div>
          </div>
        )
      } else {
        return;
      }
    });
  }

  renderIbanAdd() {
    return <div id="bank" style={{ display: (this.state.selectedIban ? 'none' : 'block') }}></div>
  }

  renderIbanBalance() {
    if (this.state.selectedIban && !_.isEmpty(this.state.balance)) {
      return (
        <div className="row">
          <div className="col-lg-12">
            <h3><Translate id="balances" /></h3>
          </div>
          {_.map(this.state.balance.balances, (balance, i) => {
            return (
              <div className='col-md-6' key={`balance-${i}`}>
                <div className="bank-detail-acc">
                  <p><strong><Translate id="sum" />: </strong> {balance.amount} {balance.currency}</p>
                  <p><strong><Translate id="status" />: </strong> {balance.balanceType}</p>
                  <p><strong><Translate id="date" />: </strong> {balance.referenceDate}</p>
                </div>
              </div>
            )
          })}
        </div>
      )
    } else {
      if (this.state.selectedIban) {
        return (
          <div className="row">
            <div className="col-lg-12">
              <h3><Translate id="balances" /></h3>
            </div>
            <div className='col-md-12'>
              <img src="/img/loading.gif" style={{ width: '50px', display: 'block', margin: '10px auto' }} />
            </div>
          </div>
        )
      }
    }
    return;
  }

  renderIbanTransactions() {
    if (this.state.selectedIban) {
      return (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="table-box">
              <h3><Translate id="transactions" /></h3>
              <table className="custom-table" style={{ width: '100%', overflow: 'scroll' }}>
                <thead>
                  <tr>
                    <th align="center">
                      <strong>#</strong>
                    </th>
                    <th>
                      <strong><Translate id="date" /></strong>
                    </th>
                    <th align="center">
                      <strong><Translate id="info" /></strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderTransactionRows()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }
    return;
  }

  renderTransactionRows() {
    setTimeout(() => {
      // const dataEvent = document.createEvent('HTMLEvents');
      // dataEvent.initEvent('datatable-data', true, true);
      // document.dispatchEvent(dataEvent);
    }, 1000);

    if (!_.isEmpty(this.state.transactions)) {
      return _.map(this.state.transactions.transactions, (transaction, i) => {
        return (
          <tr key={`transaction-${i}`}>
            <td align="center">
              <strong>{i + 1}</strong>
            </td>
            <td>
              {transaction.valueDate}
            </td>
            <td>
              {transaction.remittanceInformationUnstructured}
            </td>
          </tr>
        )
      });
    } else {
      return (
        <tr>
          <td colSpan={3}><img src="/img/loading.gif" style={{ width: '50px', display: 'block', margin: '10px auto' }} /></td>
        </tr>
      );
    }
  }

  setIbanAdd() {
    this.setState({ selectedIban: false });
  }

  render() {
    return (
      <div className="container">
          <div className="row">
            {this.head()}
            <div className="col-12 col-lg-10 col-md-9 order-cols-right">
              <div className="profile-page-cont profile-cols-height">
                <div className="profile-page-box">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="portlet-title tabbable-line tabs-separate">
                        <span>Select</span>
                        <ul className="nav nav-tabs" id="form-tabs">
                          <li className="active">
                            <a href="#bank-tab" data-toggle="tab"><Translate id="banking" /></a>
                          </li>
                          <li>
                            <a href="#transfer-tab" data-toggle="tab"><Translate id="transfer" /></a>
                          </li>
                        </ul>
                      </div>
                      <div className="white-box no-pad">
                        <div className="portlet-body">
                          <div className="tab-content">
                            <div className="tab-pane fade in active" id="bank-tab">
                              <div className="row">
                                <div className="col-lg-4 col-md-12">
                                  <h3><Translate id="bankAccounts" /></h3>
                                  {this.renderIbans()}
                                  <div onClick={() => this.setIbanAdd()}>
                                    <p className="btn-link"><Translate id="addNewAccount" /></p>
                                  </div>
                                  <div onClick={() => window.location.reload()}>
                                    <p className="btn-link"><Translate id="reload" /></p>
                                  </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                  {this.renderIbanBalance()}
                                  {this.renderIbanTransactions()}
                                  {this.renderIbanAdd()}
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade in" id="transfer-tab">
                              <div id="transfer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <UserSidebar current="bank" />
          </div>
      </div>
    );
  }
};

function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default {
  component: connect(mapStateToProps, { fetchUser, fetchUserBank, fetchIbans, fetchIbanBalance, fetchIbanTransactions })(
    requireAuth(withLocalize(BankPage))
  ),
  loadData: ({ dispatch }) => dispatch(fetchUser())
}
