import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from "react-localize-redux";
import { fetchTransactions } from '../../actions';

class TransactionsTable extends Component {
    UNSAFE_componentWillMount() {
        this.props.fetchTransactions();
    }

    componentDidUpdate() {
        const dataEvent = document.createEvent('HTMLEvents');
        dataEvent.initEvent('datatable-data', true, true);
        document.dispatchEvent(dataEvent);
    }

    transactionLink(transaction) {
        let result = "";

        switch (transaction.account_type) {
            case 'ETH':
            case 'USDT':
            case 'USDC':
            case 'UNI':
            case 'LINK':
            case 'AAVE':
            case 'COMP':
            case 'MATIC':
            case 'MKR':
            case 'MASK':
            case 'SHIB':
            case 'APE':
            case 'SNX':
            case 'CRV':
            case 'SAND':
            case 'MANA':
            case 'DYDX':
            case 'BLUR':
            case 'GALA':
            case 'STORJ':
            case 'CHZ':
            case 'SUSHI':
            case 'GRT':
            case 'LRC':
            case 'AGLD':
            case 'LOOKS':
                result = 'https://etherscan.io/tx/' + transaction.reference;
                break;
            case "BTC":
                result = 'https://explorer.bitcoin.com/btc/tx/' + transaction.reference;
                break;
            case "BCH":
                result = 'https://explorer.bitcoin.com/bch/tx/' + transaction.reference;
                break;
            case "RVN":
                result = 'https://ravencoin.network/tx/' + transaction.reference;
                break;

            case "XRP":
                result = "https://xrpscan.com/ledger/" + transaction.reference;
                break;


            default:
                break;
        }

        return result;


        // if (transaction.account_type == 'BTC') {
        //     return 'https://explorer.bitcoin.com/btc/tx/' + transaction.reference;
        // } else if (transaction.account_type == 'ETH' || transaction.account_type == 'USDT' || transaction.account_type == 'UNI' || transaction.account_type == 'LINK' || transaction.account_type == 'AAVE'
        //     || transaction.account_type == 'USDC' || transaction.account_type == 'WLD' || transaction.account_type == 'COMP' || transaction.account_type == 'AMP' || transaction.account_type == 'MATIC'
        //     || transaction.account_type == 'ARB' || transaction.account_type == 'MKR' || transaction.account_type == 'MASK' || transaction.account_type == 'SHIB' || transaction.account_type == 'PEPE'
        //     || transaction.account_type == 'APE' || transaction.account_type == 'SNX' || transaction.account_type == 'LDO' || transaction.account_type == 'CRV' || transaction.account_type == 'RNDR'
        //     || transaction.account_type == 'SAND' || transaction.account_type == 'MANA' || transaction.account_type == 'CAKE' || transaction.account_type == 'FLOKI' || transaction.account_type == 'MEME'
        // ) {
        //     return 'https://etherscan.io/tx/' + transaction.reference;
        // } else if (transaction.account_type == 'BCH') {
        //     return 'https://explorer.bitcoin.com/bch/tx/' + transaction.reference;
        // } else if (transaction.account_type == 'RVN') {
        //     return 'https://ravencoin.network/tx/' + transaction.reference;
        // } else {
        //     return '';
        // }
    }

    rows() {
        return _.map(this.props.transactions, (transaction, number) => {
            return (
                <tr key={transaction.id}>
                    <td align="center">
                        {number * 1 + 1}
                    </td>
                    <td>
                        <div className="fee-box">
                            {transaction.account_type}
                        </div>
                    </td>
                    <td align="center">
                        {transaction.action}
                    </td>
                    <td>
                        <strong className="amount">{parseFloat(transaction.amount).toFixed(transaction.decimals)} {transaction.account_type}</strong>
                    </td>
                    <td>
                        <strong className="status">{transaction.status}</strong>
                    </td>
                    <td>
                        <time>
                            {new Intl.DateTimeFormat(this.props.translate("lang"), {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            }).format(new Date(transaction.time))}
                        </time>
                    </td>
                    <td>
                        <a href={this.transactionLink(transaction)} title="" target="_blank">{transaction.reference.substring(0, 23)}...</a>
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="white-box no-pad">
                    <div className="table-box">
                        <h4 className="form-section-title">
                            <Translate id="transactions" />
                            <i className="sprite transfers-icon"></i>
                        </h4>
                        <table className="custom-table" style={{ width: '100%', overflow: 'scroll' }}>
                            <thead>
                                <tr>
                                    <th align="center">
                                        <strong>#</strong>
                                    </th>
                                    <th>
                                        <strong><Translate id="account" /></strong>
                                    </th>
                                    <th align="center">
                                        <strong><Translate id="action" /></strong>
                                    </th>
                                    <th>
                                        <strong><Translate id="amount" /></strong>
                                    </th>
                                    <th>
                                        <strong><Translate id="status" /></strong>
                                    </th>
                                    <th>
                                        <strong><Translate id="date" /></strong>
                                    </th>
                                    <th>
                                        <strong>Tx Refference</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.rows()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
};


function mapStateToProps({ transactions }) {
    return { transactions };
}

export default connect(mapStateToProps, { fetchTransactions })(withLocalize(TransactionsTable));