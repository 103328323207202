import _, { map, times } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { fetchAccounts, generateAccounts, fetchRates, searchAirports } from "../../actions";
import Account1New from "./Account1New";
import Loading from "../Loading";

class AccountsListNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      rowsPerPage: 10,
      searchInputValue: "",
      filteredBySearchResults: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.filterResults = this.filterResults.bind(this)
  }

  handleInputChange(event) {
    const newInputValue = event.target.value;
    this.setState({ searchInputValue: newInputValue }, this.filterResults)
    this.setState({ currentPage: 1 });
  }


  getRateTitle(assetType) {
    let result = "";
    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.title;
        return;
      }
    });

    if (!result) {
      result = "";
    }

    return result;
  };

  filterResults() {
    const allAccountsArray = Object.values(this.props.accounts);
    const searchedCurrency = this.state.searchInputValue;

    const filteredSearchResults = allAccountsArray.filter(item =>
      this.getRateTitle(item.type).toLowerCase().includes(searchedCurrency.toLocaleLowerCase()) ||
      item.type.toLowerCase().includes(searchedCurrency.toLocaleLowerCase())
    );

    if (filteredSearchResults.length === 0) {

    }

    this.setState({ filteredBySearchResults: filteredSearchResults });
  }

  handlePageChange(newPage) {
    this.setState({ currentPage: newPage });
  };

  UNSAFE_componentWillMount() {
    this.props.fetchAccounts();
    this.props.fetchRates();
  }

  // onGenerateAccounts() {
  //   this.props.generateAccounts().then(() => {
  //     setTimeout(() => {
  //       this.props.fetchAccounts();
  //     }, 1000);
  //   });
  // }

  // getPositiveBalances(positiveAccountsBalance) {
  //   let result = false;
  //   _.map(this.props.accounts, (account, i) => {
  //     if (account.balance > 0) {
  //       positiveAccountsBalance.push(account)
  //       return;
  //     }
  //   });

  //   return result;
  // };

  getRate(assetType, assetAmount) {
    let rate = 0;

    _.map(this.props.rates, (itemRate, i) => {
      let tempRate = parseFloat(itemRate.rate / this.props.currency.rate);
      if (assetType == 'USDT' && this.props.currency.type == 'USDT') {
        rate = parseFloat(assetAmount);
      } else if (itemRate.type == assetType) {
        rate = parseFloat(assetAmount * tempRate);
      }
    });

    return rate.toFixed(2);
  };

  getRateId(assetType) {
    let result = 0;

    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.id;
        return;
      }
    });

    return result;
  };

  getRateOrderPos(assetType) {
    let result = 0;

    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.order_pos;
        return;
      }
    });

    return result;
  };

  getRateTradeDisabled(assetType) {
    let result = false;

    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.trade_disabled;
        return;
      }
    });

    return result;
  };

  isTradeDisabled(assetType) {
    let result = false;

    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.trade_disabled;
        return;
      }
    });

    return result;
  };

  render() {
    let counter = 0;

    if (!_.isEmpty(this.props.accounts)) {

      const { currentPage, rowsPerPage } = this.state;
      const data = Object.values(this.state.searchInputValue !== "" ? this.state.filteredBySearchResults : this.props.accounts);

      const sortedAccounts = _.orderBy(data, [
        account => parseFloat(this.getRate(account.type, account.balance)),
        account => parseFloat(this.getRateOrderPos(account.type)),
        account => parseFloat(this.getRateTradeDisabled(account.type)),
        account => parseFloat(this.getRateId(account.type))
      ], ['desc', 'asc', 'asc', 'asc']);

      const startIdx = (currentPage - 1) * rowsPerPage;
      // const accountsOnPage = Object.values(sortedAccounts).slice(startIdx, startIdx + rowsPerPage);
      const accountsOnPage = (this.state.searchInputValue !== "" ? this.state.filteredBySearchResults : Object.values(sortedAccounts)).slice(startIdx, startIdx + rowsPerPage);
      const totalPages = Math.ceil(data.length / rowsPerPage);
      // const startingPage = currentPage > 5 ? 5 * (currentPage / 5) - 1 : 0



      return (
        <div>
          <h1 style={{ color: 'white' }}><Translate id="assets" /></h1>
          <div >
            <input
              type="text"
              className="input-search-currency"
              placeholder="Search..."
              value={this.state.searchInputValue}
              onChange={this.handleInputChange}
              onKeyUp={this.handleInputChange}
              style={{ color: "grey", backgroundColor: "transparent" }}
            >

            </input>
            <div className="row" style={{ color: 'white', marginBottom: "30px", marginTop: "25px" }}>
              <ol>
                <li className="col-sm-3 col-xs-6"><Translate id="name" /></li>
                <li className="col-sm-4 col-xs-6"><Translate id="total" /></li>
              </ol>
            </div>
            <div>

              {this.state.searchInputValue !== "" && this.state.filteredBySearchResults.length === 0 ? (<h3 style={{ color: "white" }}>No results</h3>) : ""}
              {_.map(accountsOnPage, account => {
                return (
                  <Account1New
                    key={++counter}
                    account={account}
                    rates={this.props.rates}
                    currency={this.props.currency}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <button className="pagination-button-new" type="button" onClick={() => this.handlePageChange(1)}>
              <i className="fal fa-chevron-double-left"></i>
            </button>
            <button className="pagination-button-new" onClick={() => currentPage > 1 ? this.handlePageChange(currentPage - 1) : ""}>
              <i className="fal fa-chevron-left"></i>
            </button>
            {Array.from({ length: totalPages }, (_, idx) => (
              <button
                key={idx}
                onClick={() => this.handlePageChange(idx + 1)}
                disabled={currentPage === idx + 1}
                className={`pagination-button-new ${currentPage === idx + 1 ? `active-button` : ``}`}
              >
                {idx + 1}
              </button>
            )).slice(currentPage < 4 ? 0 : currentPage - 3, currentPage < 4 ? 5 : currentPage + 2)
              // .slice(startingPage, currentPage < 5 ? 5 : startingPage + 5)

            }
            <button className="pagination-button-new" onClick={() => currentPage === totalPages ? "" : this.handlePageChange(currentPage + 1)}>
              <i className="fal fa-chevron-right"></i>
            </button>
            {
              <button className="pagination-button-new" onClick={() => this.handlePageChange(totalPages)}>
                <i className="fal fa-chevron-double-right"></i>
              </button>
            }
          </div>
        </div>
      );
    } else {
      return <Loading />
      // return (
      //   <div>
      //     <p>
      //       <Translate id="empty.accounts" />
      //     </p>
      //     <button
      //       onClick={this.onGenerateAccounts.bind(this)}
      //       className="btn btn-action"
      //     >
      //       <Translate id="generate.new" />
      //     </button>
      //   </div>
      // );
    }
  }
}

function mapStateToProps({ accounts, rates, currency }) {
  return { accounts, rates, currency };
}

export default connect(
  mapStateToProps,
  { fetchAccounts, generateAccounts, fetchRates }
)(AccountsListNew);
