import React, { Component } from 'react';
import { renderRoutes } from 'react-router-config';
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';
import Header from './components/Header';
import Footer from './components/Footer';
import { fetchUser, fetchConfig, fetchRates, fetchCountry, fetchCurrencies, setCurrency } from './actions';
import ChatIcon from './components/ChatIcon';

class App extends Component {
    UNSAFE_componentWillMount() {
        if (this.props.auth.authenticated == true) {
            this.props.fetchUser();
        }
        this.props.fetchConfig();
        this.props.fetchRates();

        if (typeof localStorage != 'undefined' && !localStorage.getItem("languageCode")) {
            this.props.fetchCountry().then(country => {
                if (country && country.length) {
                    const countryData = country[0];
                    // if (['BG'].indexOf(countryData.country) != -1) {
                    //     this.props.setActiveLanguage(countryData.country.toLowerCase());
                    //     localStorage.setItem("languageCode", countryData.country.toLowerCase());
                    //     if (countryData.country != 'BG') {
                    //         this.props.fetchCurrencies().then(currencies => {
                    //             if (currencies && currencies['EUR']) {
                    //                 this.props.setCurrency(currencies['EUR']);
                    //             }
                    //         });
                    //     }
                    // } else {
                    this.props.setActiveLanguage('en');
                    localStorage.setItem("languageCode", 'en');
                    this.props.fetchCurrencies().then(currencies => {
                        if (currencies) {
                            if (currencies['EUR']) {
                                this.props.setCurrency(currencies['EUR']);
                            }
                            else if (currencies['USDT']) {
                                this.props.setCurrency(currencies['USDT']);
                            }
                        }
                    });
                    // }
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code;
        const curLangCode = this.props.activeLanguage && this.props.activeLanguage.code;
        const hasLanguageChanged = prevLangCode !== curLangCode;
        if (hasLanguageChanged && localStorage) {
            localStorage.setItem("languageCode", curLangCode);
        }
    }

    renderMessage() {
        return (
            <div id="bottom-msg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="text">
                                <a className="btn-link" href="javascript:;" title="">Agree</a>
                                <a className="btn-link reverse" href="javascript:;" title="">Close</a>
                                <a className="close-msg" href="javascript:;" title=""><i className="fal fa-times"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div id="body-root" className={`${this.props.auth.authenticated == true && this.props.user.nickname ? 'is-logged' : 'not-logged'}`}>
                <Header />
                <main>
                    {renderRoutes(this.props.route.routes)}
                </main>
                <Footer />
                {/* <div id="fb-root" className={`${this.props.auth.authenticated == true && this.props.user.nickname ? 'msngr-box offset' : 'msngr-box'}`}></div> */}
                <ChatIcon />
            </div>
        );
    }
};

function mapStateToProps({ auth, user, config, rates }) {
    return { auth, user, config, rates };
}

export default {
    component: connect(mapStateToProps, { fetchUser, fetchConfig, fetchRates, fetchCountry, fetchCurrencies, setCurrency })(
        withLocalize(App)
    ),
    loadData: ({ dispatch }) => dispatch(fetchUser())
};
