import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Translate } from "react-localize-redux";
import AssetsHomeTableItem from './AssetsHomeTableItem';

class AssetsTable extends Component {

  render() {
    return (
      <div className="col-sm-12 mb-5">
        <table className="table-box-style table-box-style-list mb-5">
          <tbody>
            {_.map(this.props.rates, (rate) => {
              if ((!rate.is_fiat || rate.type == 'USDT') && !rate.trade_disabled) {
                return (
                  <AssetsHomeTableItem {...rate} key={rate.id} user={this.props.user} config={this.props.config} />
                )
              }
            })}
          </tbody>
        </table>
      </div>
    );
  }
};


function mapStateToProps({ user, rates, config }) {
  return { user, rates, config };
}

export default connect(mapStateToProps)(AssetsTable);
