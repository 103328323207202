import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { fetchUser } from '../../actions';
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebarNew from '../../components/users/UserSidebarNew';
import AccountsBalances from '../../components/accounts/AccountsBalances';
import UserHeader from '../../components/users/UserHeader';
import AssetsWidget from '../../components/assets/AssetsWidget';
import AffiliatePageNew from './AffiliatePageNew';
import BonusPageNew from './BonusPageNew';
import UserHeaderNew from '../../components/users/UserHeaderNew';

class ProfilePageNew extends Component {
    constructor(props) {
        super(props);
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    head() {
        return (
            <Helmet>
                <title>{`Профил - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Профил - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidMount() {
        if (typeof window != 'undefined') {
            window.scrollTo(0, 0);
        }

        this.props.fetchUser();
    }

    copyToClipboard(e) {
        this.defiCode.select();
        document.execCommand("copy");
    }

    render() {
        return (
            <div className="container">
                <div className="">
                    {this.head()}
                    <UserSidebarNew current="profile"/>
                    <div className='container profile-page-new-wrapper user-header-wrapper'>
                        <UserHeaderNew />
                    </div>
                    <div className='profile-page-new-wrapper bonus-wrapper'>
                        <div className='affiliate-box'>
                            <BonusPageNew />
                        </div>
                    </div>
                    <div className="" >
                        <div className="profile-page-new-wrapper">
                            <div className='affiliate-box'>
                                <AffiliatePageNew style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth, user }) {
    return { auth, user };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(
        requireAuth(ProfilePageNew)
    ),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
