import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Translate } from "react-localize-redux";
import { fetchUser, updateGeneralSettings } from '../../actions';
import { addressRegExes, globalRegExes } from '../../utilities/RegEx';
import { validateIban } from '../../utilities/Validation';

const CRYPTO_FIELDS = {
    bitcoin_address: {
        type: 'input',
        label: 'bitcoinAddress',
        required: false,
        address_type: 'BTC',
        errorMsg: 'addressIsInvalid'
    },
    ethereum_address: {
        type: 'input',
        label: 'ethereumAddress',
        required: false,
        address_type: 'ETH',
        errorMsg: 'addressIsInvalid'
    },
    bitcoin_cash_address: {
        type: 'input',
        required: false,
        label: 'bitcoinCashAddress',
        address_type: 'BCH',
        errorMsg: 'addressIsInvalid'
    }
};

const BANK_FIELDS = {
    bank_iban: {
        type: 'input',
        label: 'iban',
        required: false,
        errorMsg: 'bankIbanIsInvalid'
    },
    bank_bic: {
        type: 'input',
        label: 'bankBic',
        required: false,
        errorMsg: 'bankBicIsInvalid',
    },
    bank_holder: {
        type: 'input',
        label: 'bankHolder',
        required: false,
        errorMsg: 'bankHolderIsInvalid'
    },
    bank_currency: {
        type: 'input',
        label: 'currency',
        required: false,
        errorMsg: 'currencyIsInvalid'
    },
    bank_name: {
        type: 'input',
        label: 'bankName',
        required: false,
        errorMsg: 'bankNameIsInvalid',
    },
    bank_address: {
        type: 'input',
        label: 'bankAddress',
        required: false,
        errorMsg: 'bankAddressIsInvalid',
    }
}

class GeneralSettingsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false
        };
    }

    renderField(field) {
        const { input, label, type, meta: { touched, error } } = field;
        const className = `col-md-6 form-group ${touched && error ? 'has-error' : 'has-success'}`;

        return (
            <div className={className}>
                <div className="input-icon">
                    <div className="label-animated has-content">
                        <input 
                            {...input}
                            type={type}
                            className="form-control placeholder-no-fix" 
                            autoComplete="off"
                        />
                        <label className="control-label visible-ie8 visible-ie9"><Translate id={label} /></label>
                        {touched && error ? (
                            <div className="help-block with-errors" style={{marginLeft: '16px'}}>
                                    <span><Translate id={`errors.${error}`} /></span>
                            </div> 
                        ) : ''}
                    </div>
                </div>
            </div>
        );
    }

    onSubmit(values) {
        this.props.updateGeneralSettings(values).then(() => {
            this.setState({ 
                submitted: true 
            });
        });
    }

    render() {
        const { handleSubmit } = this.props;

        if(this.state.submitted) {
            return (
                <div className="infoFigureBox">
                    <div className="row">
                        <div className="col-12">
                            <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                                <h3><Translate id="successLabel" />!</h3>
                            </header>
                        </div>
                        <div className="col-md-offset-2 col-md-8">
                            <div className="success-msg-box">
                                <figure><i className="far fa-check-circle"></i></figure>
                                <h5><Translate id="success.settings" /></h5>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="infoFigureBox">
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <div className="row">
                            <div className="col-md-12 element-section-title has-bgr-element text-center crypto-sell-color" style={{ padding: '0 50px 46px'}}>
                                <h3><Translate id="myCryptoAddresses" /></h3>
                            </div>
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <p><Translate id="forAutoCrypto" /></p>
                                    <hr />
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-12">
                                {_.map(CRYPTO_FIELDS, (field, fieldName) => {
                                    return <Field name={fieldName} component={this.renderField} { ...field } key={fieldName} />
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 element-section-title has-bgr-element text-center crypto-sell-color">
                                <h3><Translate id="myBankInfo" /></h3>
                            </div>
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <p><Translate id="forAutoBank" /></p>
                                    <hr />
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-12">
                                {_.map(BANK_FIELDS, (field, fieldName) => {
                                    return <Field name={fieldName} component={this.renderField} { ...field } key={fieldName} />
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10"></div>
                            <div className="col-md-2">
                                <div className="col align-self-end">
                                    <button type="submit"><Translate id="save" /></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>          
            );
        }
    }
};

function validate(formProps) {
    const errors = {};

    _.each(CRYPTO_FIELDS, (field, fieldName) => {
        if (!formProps[fieldName] && field.required) {
            errors[fieldName] = field.errorMsg;
        }

        if(formProps[fieldName]) {
            if(field.address_type != 'BCH' && !formProps[fieldName].match(addressRegExes[field.address_type])) {
                errors[fieldName] = field.errorMsg;
            } else if(field.address_type == 'BCH' && !formProps[fieldName].match(addressRegExes[field.address_type]) 
                && !formProps[fieldName].match(addressRegExes[field.address_type+'_2'])
                && !formProps[fieldName].match(addressRegExes[field.address_type+'_3'])) {
                    errors[fieldName] = field.errorMsg;
            }
        }
    });

    _.each(BANK_FIELDS, (field, fieldName) => {
        if (!formProps[fieldName] && field.required) {
            errors[fieldName] = field.errorMsg;
        }

        if(formProps[fieldName]) {
            if(fieldName == 'bank_iban' && !validateIban(formProps[fieldName])) {
                errors[fieldName] = field.errorMsg;
            }

            if(fieldName == 'bank_bic' && !formProps[fieldName].match(globalRegExes[fieldName])) {
                errors[fieldName] = field.errorMsg;
            }
        }
    });
    return errors;
}

function mapStateToProps({ user }) {
    return {
        initialValues: user
    };
}

export default connect(mapStateToProps, { fetchUser, updateGeneralSettings })(
    reduxForm({
        validate,
        form: 'updateGeneralSettingsForm',
        fields: _.merge({}, CRYPTO_FIELDS, BANK_FIELDS),
        enableReinitialize : true
    })(GeneralSettingsForm)
)