import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Translate } from "react-localize-redux";
import AssetsHomeTable from "../components/assets/AssetsHomeTable";
import CommissionPopup from "../components/CommissionPopup";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zeroCommissionPopupIsOpen: true,
    };

    this.onClosePopupClick = this.onClosePopupClick.bind(this);
  }
  componentDidMount() {
    if (this.state.zeroCommissionPopupIsOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.height = "auto";
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.zeroCommissionPopupIsOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }

  onClosePopupClick() {
    this.setState({ zeroCommissionPopupIsOpen: false });
  }

  head() {
    return (
      <Helmet>
        <title>{`BoneX | Crypto Exchange`}</title>
        <meta property="og:title" content={`BoneX | Crypto Exchange`} />
        <meta
          property="og:description"
          content="Покупка и продажба на Биткойн, Етер, Биткойн кеш. Софтуер, гарантиращ най-добрата цена на пазара в България."
        />
        <meta
          property="og:image"
          content="https://bonex.org/img/bonex_og.jpg"
        />
        <meta property="og:url" content="https://bonex.org" />
        <meta property="og:type" content="website" />
      </Helmet>
    );
  }

  render() {
    return (
      <div>
        {this.head()}
        <div className="row">
          <div className="col">
            <div className="container index-partner-section">
              <a href="/fireblocks">
                <img src="/img/banner_fireblocks.png" alt="Fireblocks" />
              </a>
            </div>
          </div>
        </div>
        <div id="page-intro">
          <div className="container">
            <div className="col-sm-12">
              <h1>
                <span className="white-text">Free the</span>
                <span className="white-text">Money.</span>
                <span className="white-text">Free the</span>
                <span className="blue-text">WORLD.</span>
              </h1>
            </div>
            <AssetsHomeTable />
          </div>
          <iframe
            src="https://my.spline.design/earthdayandnight-f6b94d3f292d3b9bdc32a608f3f2f752/"
            frameBorder="0"
            width="100%"
            height="100%"
          ></iframe>
        </div>
        <div className="assets-box">
          <div className="container">
            <div className="col-sm-12">
              <div className="assets-list-box">
                <div className="row">
                  <div className="col-12 col-md-4 mb-5 text-center text-lg-left">
                    <h2 className="section-title-box">
                      <span>Your Assets</span>
                      <span>are Our</span>
                      <span>Priority</span>
                    </h2>
                  </div>
                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry">
                      <figure>
                        <img src="/img/home/asset-1.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/defi">
                            <Translate id="aiTrading" />
                          </Link>
                        </h3>
                        <p>
                          <Translate id="aiTradingText" />
                        </p>
                        <Link to="/defi">
                          <Translate id="readMore" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry">
                      <figure>
                        <img src="/img/home/asset-2.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/open-banking">
                            <Translate id="banking" />
                          </Link>
                        </h3>
                        <p>
                          <Translate id="bankingText" />
                        </p>
                        <Link to="/open-banking">
                          <Translate id="readMore" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry">
                      <figure>
                        <img src="/img/home/asset-3.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/buy-property">
                            <Translate id="buyProperty" />
                          </Link>
                        </h3>
                        <p>
                          <Translate id="buyPropertyText" />
                        </p>
                        <Link to="/buy-property">
                          <Translate id="readMore" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry">
                      <figure>
                        <img src="/img/home/asset-4.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/crypto-accounting">
                            <Translate id="cryptoAccounting" />
                          </Link>
                        </h3>
                        <p>
                          <Translate id="cryptoAccountingText" />
                        </p>
                        <Link to="/crypto-accounting">
                          <Translate id="readMore" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-5">
                    <div className="assets-list-entry">
                      <figure>
                        <img src="/img/home/asset-5.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3>
                          <Link to="/book-flight">
                            <Translate id="bookFlight" />
                          </Link>
                        </h3>
                        <p>
                          <Translate id="bookFlightText" />
                        </p>
                        <Link to="/book-flight">
                          <Translate id="readMore" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explore-box">
          <div className="container">
            <div className="col-sm-12">
              <h2 className="section-title-box text-center mb-5 style-2">
                <span>Explore</span>
                <span>the power</span>
                <span>of Bonex</span>
              </h2>
              <div className="explore-list-box">
                <div className="row">
                  <div className="col-sm-12 col-lg-4">
                    <div className="explore-list-entry">
                      <figure>
                        <img src="/img/home/explore-1.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3 className="white-text">
                          <Translate id="home.benefitOne" />
                        </h3>
                        <p>
                          <Translate id="home.benefitOneText" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <div className="explore-list-entry">
                      <figure>
                        <img src="/img/home/explore-2.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3 className="white-text">
                          <Translate id="home.benefitTwo" />
                        </h3>
                        <p>
                          <Translate id="home.benefitTwoText" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <div className="explore-list-entry">
                      <figure>
                        <img src="/img/home/explore-3.png" alt="" />
                      </figure>
                      <div className="text">
                        <h3 className="white-text">
                          <Translate id="home.benefitThree" />
                        </h3>
                        <p>
                          <Translate id="home.benefitThreeText" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.zeroCommissionPopupIsOpen && (
          <CommissionPopup onClosePopupClick={this.onClosePopupClick} />
        )}
      </div>
    );
  }
}

export default {
  component: HomePage,
};
