import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import AssetRates from "../assets/AssetRates";
import Loading from "../Loading";
import {
  fetchTransaction,
  sendTransaction,
  clearTransaction,
  setError
} from "../../actions";
import { formatMoney, formatCrypto } from "../../utilities/Format";

const paymentMethods = {
  account: "address"
};

const receivingMethods = {
  account: "account"
};

class AccountDepositForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0.0,
      price: 0.0,
      total: 0.0,
      rate: 0.0,
      copied: false,
      paymentMethod: "address",
      receivingMethod: "account",
      receivingMethodValid: true,
      formErrors: {
        amount: "",
        price: "",
        paymentMethod: "",
        receiver: "",
        receivingMethod: ""
      }
    };

    this.onCalculatorChange = this.onCalculatorChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  onCalculatorChange(amount, price, field, rate) {
    this.setState({ amount, price, total: price, rate });
  }

  onReceivingMethodChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ receivingMethod: value });
  }

  onSubmit(event) {
    event.preventDefault();
    this.props.sendTransaction({
      ...this.state,
      account: this.props.account,
      type: "DEPOSIT",
      currency: this.props.currency
    });
  }

  componentWillUnmount() {
    this.props.clearTransaction();
  }

  copyToClipboard(e) {
    this.cryptoAddress.select();
    setTimeout(() => {
      document.execCommand("copy");
      this.setState({
        copied: true
      });
    }, 500);
  }

  render() {
    if (this.props.auth.authenticated && this.props.user.totp == 'none') {
      this.props.setError('2FA Authenticaton Required');
      return (
        <Redirect to="/settings" />
      );
    }

    if (!_.isEmpty(this.props.account)) {
      // const identificatorPair = this.props.account.identificator.split(":");

      return (
        <div className="account-action-deposit-form-wrapper">
          <div className="row" style={{width: "100%"}}>
            <div className="">
              <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                <h3>
                  <Translate id="depositTexts.pt1" /> {this.props.account.title}
                  !
                </h3>
              </header>
            </div>
            <div className="">
              <div className="success-msg-box">
                <figure>
                  <i className="far fa-check-circle"></i>
                </figure>
                <p>
                  <Translate id="depositTexts.pt2" /> {this.props.account.type} <Translate id="depositTexts.pt3" />  <Translate id="depositTexts.pt4" />
                </p>
                {/* <p>
                  <Translate id="depositTexts.pt3" />
                  <Translate id="depositTexts.pt4" />:
                </p> */}

                <p><Translate id="depositTexts.network" />: <strong>{this.props.account.network}</strong></p>
                {this.props.numberConfirmations > 0 &&
                  <p><Translate id="depositTexts.number-confirmations" />: <strong>{this.props.numberConfirmations}</strong></p>
                }

                {/* {(['ETH', 'USDT', 'UNI', 'LINK', 'AAVE', 'USDC', 'WLD', 'COMP', 'AMP', 'MATIC', 'ARB', 'MKR', 'MASK', 'SHIB', 'PEPE', 'APE', 'SNX', 'LDO', 'CRV', 'RNDR', 'SAND', 'MANA', 'CAKE', 'FLOKI', 'MEME'].indexOf(this.props.account.type) != -1) ? (
                  <p><strong style={{ color: 'red' }} >Network: ERC-20 (ETH)</strong></p>
                ) : ""} */}
                <p>
                  {/* <i
                    className="fa fa-copy"
                    style={{ paddingRight: "15px" }}
                    onDoubleClick={this.copyToClipboard}
                    title="Copy"
                  ></i> */}
                  <span style={{overflowWrap: "anywhere"}}>Address: {this.props.account.identificator}</span>
                </p>
                {/* {identificatorPair.length > 1 &&
                  <div>
                    <p>
                      <span>MEMO: {identificatorPair[1]}</span>
                    </p>
                    <p style={{ color: "red" }}> <Translate id="depositTexts.memo-required" /></p>
                  </div>} */}

                {this.props.minDeposit > 0 &&
                  <p>
                    <span style={{ color: "red" }}><Translate id="depositTexts.min-deposit" />: {this.props.minDeposit}</span>
                  </p>
                }

                {/* <input
                  type="hidden"
                  ref={cryptoAddress => (this.cryptoAddress = cryptoAddress)}
                  value={this.props.account.identificator}
                /> */}
                {/* {this.state.copied ? (
                  <span>
                    <br />
                    <Translate id="copied" />
                  </span>
                ) : (
                  ""
                )} */}
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps({ user, auth, transaction, currency }) {
  return { user, auth, transaction, currency };
}

export default connect(
  mapStateToProps,
  { fetchTransaction, sendTransaction, clearTransaction, setError }
)(AccountDepositForm);
