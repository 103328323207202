import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { withLocalize, Translate } from "react-localize-redux";
import AssetRates from "../assets/AssetRates";
import Loading from "../Loading";
import {
  fetchTransaction,
  sendTransaction,
  clearTransaction,
  validatePromoCode,
  setError
} from "../../actions";
import { formatMoney, formatCrypto } from "../../utilities/Format";
import { amountRegExes, globalRegExes } from "../../utilities/RegEx";
import { validateIban } from "../../utilities/Validation";
import AssetRatesNew from "../assets/AssetRatesNew";

const paymentMethods = {
  account: "account",
  address: "address"
};

const receivingMethods = {
  bank: "bank",
  // epay: 'epay',
  easypay: "easypay",
  usdt: "usdt"
};

const receivingMethodsFees = {
  bank: 0,
  // epay: 0,
  easypay: 0,
  usdt: 0
};

const receivingMethodsIcons = {
  bank: "/img/bank.png",
  // epay: '/img/epay.png',
  easypay: "/img/easy-pay.png",
  usdt: "/img/assets/usdt.png",
};

class AccountSellFormNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rateSub: 0.0,
      amount: 0.0,
      price: 0.0,
      fees: 0.0,
      total: 0.0,
      rate: 0.0,
      paymentMethod: "address",
      receivingMethod: "bank",
      receiver: "",
      bank_holder: "",
      bank_iban: "",
      epay_kin: "",
      easypay_names: "",
      easypay_egn: "",
      easypay_phone: "",
      formValid: false,
      formErrors: {
        amount: "",
        price: "",
        paymentMethod: "",
        receiver: "",
        receivingMethod: "",
        receivingInfo: "",
        bankHolder: "",
        bankIban: "",
        epayKin: "",
        easyPayNames: "",
        easyPayEgn: "",
        easyPayPhone: "",
        promoCode: ""
      },
      amountValid: false,
      priceValid: false,
      paymentMethodValid: true,
      receiverValid: true,
      receivingMethodValid: true,
      receivingInfoValid: false,
      bankHolderValid: false,
      bankIbanValid: false,
      epayKinValid: false,
      easyPayNamesValid: false,
      easyPayEgnValid: false,
      easyPayPhoneValid: false,
      copied: false,
      isLoading: false,
      promoCode: "",
      promoCodeUse: false
    };

    this.onCalculatorChange = this.onCalculatorChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  componentDidMount() {
    if (this.props.currency.type == 'USDT') {
      const receivingMethod = "usdt";
      this.setState({ receivingMethod });
      this.callParentAction(receivingMethod);
    }
  }

  onCalculatorChange(amount, price, field, rate) {
    this.setState({ amount, price, rate }, async () => {
      return this.validateField(
        field,
        field === "amount" ? amount : price
      ).then(resp => {
        return this.validateField(
          field === "amount" ? "price" : "amount",
          field === "amount" ? price : amount
        ).then(resp => {
          this.recalculateTotal();
        });
      });
    });
  }

  onPaymentMethodChange(event) {
    this.setState({ paymentMethod: event.target.value }, () => {
      this.validateField("price", this.state.price);
    });
  }

  onReceivingMethodChange(event) {
    let { value } = event.target;
    this.changeReceivingMethod(value);
    this.callParentAction(value);
  }

  onFieldChange(event) {
    const { name, value } = event.target;
    let diff = {};
    diff[name] = value;
    this.setState({ ...diff }, () => {
      this.validateField(name, value);
    });
  }

  onPromoCodeChange(event) {
    let value = event.target.value;
    this.setState({ rateSub: 0.0, promoCode: value, formErrors: { ...this.state.formErrors, promoCode: "" } });
  }

  onPromoCodeSubmit() {
    this.setState({ rateSub: 0.0, formErrors: { ...this.state.formErrors, promoCode: "" } });
    if (this.state.promoCode != '') {
      this.props.validatePromoCode({ promoCode: this.state.promoCode }).then(resp => {
        if (resp.success) {
          this.setState({ rateSub: parseFloat(this.props.config.cashback_sub), promoCodeUse: true }, () => {
            this.recalculateTotal();
          });
        } else {
          this.setState({ formErrors: { ...this.state.formErrors, promoCode: "promoCodeIsInvalid" } });
        }
      });
    }
  }

  onSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true }, () => {
      this.props.sendTransaction({
        ...this.state,
        account: this.props.account,
        type: "SELL",
        currency: this.props.currency
      }).then(resp => {
        this.setState({ isLoading: false });
      });
    });
  }

  callParentAction(value) {
    if (this.props.action) {
      this.props.action(value);
    }
  }

  getPrice(type, amount, action) {
    let price = 0;
    _.map(this.props.rates, (aRate, i) => {
      let tempRate = aRate.rate;
      tempRate =
        parseFloat(aRate.rate / this.props.currency.rate) -
        (parseFloat(aRate.rate / this.props.currency.rate) *
          (this.props.config.sell_percent - this.state.rateSub)
        ) /
        100;

      if (aRate.type == type && action == 'amount') {
        price = parseFloat(amount * tempRate).toFixed(2);
      } else if (aRate.type == this.props.account.type && action == 'price') {
        price = parseFloat(amount / tempRate).toFixed(this.props.account.decimals);
      }
    });
    return price;
  }

  getMaxPrice(type, amount) {
    let price = 0;
    _.map(this.props.rates, (aRate, i) => {
      let tempRate = aRate.rate;
      tempRate =
        parseFloat(aRate.rate) -
        (parseFloat(aRate.rate) *
          (this.props.config.sell_percent - this.state.rateSub)
        ) /
        100;

      if (aRate.type == type) {
        price = parseFloat(amount * tempRate).toFixed(2);
      }
    });
    return price;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.changeReceivingMethod(props.receivingMethod);
  }

  componentWillUnmount() {
    this.props.clearTransaction();
  }

  changeReceivingMethod(value) {
    this.setState({ receivingMethod: value }, () => {
      this.validateField("receivingMethod", value).then(async resp => {
        let change = {};
        if (value == "bank") {
          change = {
            bank_holder: this.props.user.bank_holder,
            bankHolderValid: this.props.user.bank_holder && this.props.user.bank_holder.length > 0,
            bank_iban: this.props.user.bank_iban,
            bankIbanValid: this.props.user.bank_iban && this.props.user.bank_iban.length > 0,
            receivingInfoValid:
              this.props.user.bank_iban && this.props.user.bank_holder.length > 0 &&
              this.props.user.bank_iban.length > 0
          };
        } else if (value == "epay") {
          change = {
            epay_kin: this.props.user.epay_kin,
            epayKinValid: this.props.user.epay_kin.length > 0,
            receivingInfoValid: this.props.user.epay_kin.length > 0
          };
        } else if (value == "easypay") {
          change = {
            easypay_names:
              this.props.user.first_name +
              " " +
              this.props.user.middle_name +
              " " +
              this.props.user.last_name,
            easyPayNamesValid: this.state.easypay_names.length > 0,
            easypay_egn: this.props.user.egn || this.state.easypay_egn,
            easyPayEgnValid:
              (this.props.user.egn && this.props.user.egn.length > 0) ||
              this.state.easypay_egn.length > 0,
            easypay_phone: this.props.user.phone || this.state.easypay_phone,
            easyPayPhoneValid:
              (this.props.user.phone && this.props.user.phone.length > 0) ||
              this.state.easypay_phone.length > 0,
            receivingInfoValid:
              this.state.easypay_names.length > 0 &&
              this.props.user.egn && this.props.user.egn.length > 0 &&
              this.props.user.phone && this.props.user.phone.length > 0
          };
        } else if (value == "usdt") {
          change = {
            paymentMethod: "account",
            paymentMethodValid: true,
            receivingInfoValid: true
          };
        }

        this.setState(
          {
            ...change
          },
          () => {
            this.validateField("price", this.state.price);
            this.recalculateTotal();
          }
        );
      });
    });
  }

  recalculateTotal() {
    let total = 0.0;
    let price = parseFloat(this.state.price);
    let fees = this.getReceivingMethodFees();

    total = price - fees;
    this.setState({
      fees,
      total
    });
  }

  getReceivingMethodFees() {
    if (this.state.receivingMethod == "easypay") {
      let price = parseFloat(this.state.price);
      let fees = price * 0.019;
      return fees;
    } else {
      return receivingMethodsFees[this.state.receivingMethod];
    }
  }

  validateField(fieldName, value) {
    return new Promise(resolve => {
      let formErrors = this.state.formErrors;
      let amountValid = this.state.amountValid;
      let priceValid = this.state.priceValid;
      let paymentMethodValid = this.state.paymentMethodValid;
      let receiverValid = this.state.receiverValid;
      let receivingMethodValid = this.state.receivingMethodValid;
      let receivingInfoValid = this.state.receivingInfoValid;
      let bankHolderValid = this.state.bankHolderValid;
      let bankIbanValid = this.state.bankIbanValid;
      let epayKinValid = this.state.epayKinValid;
      let easyPayNamesValid = this.state.easyPayNamesValid;
      let easyPayEgnValid = this.state.easyPayEgnValid;
      let easyPayPhoneValid = this.state.easyPayPhoneValid;

      switch (fieldName) {
        case "amount":
          amountValid =
            parseFloat(value).toPrecision(9) != "0.00000000" &&
            value.match(amountRegExes.crypto);
          formErrors.amount = amountValid ? "" : "IsInvalid";
          break;
        case "price":
          formErrors.price =
            value && value.match(amountRegExes.fiat) ? "" : "IsInvalid";
          if (value * this.props.currency.rate < this.props.config.sell_min) {
            //   (this.state.paymentMethod == "address" &&
            //      ||
            //   (this.state.paymentMethod == "account" &&
            //     value * 1 <
            //     this.getPrice(
            //       this.props.account.type,
            //       this.props.account.balance > 0 ? 0.001 : 0
            //     ))
            // ) {

            formErrors.price = "IsBelowMin";
          } else if (
            (this.state.paymentMethod == "address" && value * this.props.currency.rate > this.props.config.sell_max) ||
            (this.state.paymentMethod == "account" && value * this.props.currency.rate > this.getMaxPrice(this.props.account.type, this.props.account.balance)) ||
            (this.state.receivingMethod === "easypay" && value * this.props.currency.rate > 4900)
          ) {
            formErrors.price = "IsAboveMax";
          }
          priceValid = !formErrors.price.length;
          break;
        case "paymentMethod":
          paymentMethodValid = paymentMethods[value].length > -1;
          formErrors.paymentMethod = paymentMethodValid ? "" : "IsInvalid";
          break;
        case "receivingMethod":
          receivingMethodValid = receivingMethods[value].length > -1;
          formErrors.receivingMethod = receivingMethodValid ? "" : "IsInvalid";
          break;
        case "bank_holder":
          bankHolderValid = value != "";
          formErrors.bankHolder = bankHolderValid ? "" : "IsInvalid";
          break;
        case "bank_iban":
          bankIbanValid = value != "";
          bankIbanValid = validateIban(value);
          formErrors.bankIban = bankIbanValid ? "" : "IsInvalid";
          break;
        case "epay_kin":
          epayKinValid = value != "";
          epayKinValid = value.match(globalRegExes.epay_kin);
          formErrors.epayKin = epayKinValid ? "" : "IsInvalid";
          break;
        case "easypay_names":
          easyPayNamesValid = value != "";
          easyPayNamesValid = value.trim().split(" ").length > 2;
          formErrors.easyPayNames = easyPayNamesValid ? "" : "IsInvalid";
          break;
        case "easypay_egn":
          easyPayEgnValid = value != "";
          formErrors.easyPayEgn = easyPayEgnValid ? "" : "IsInvalid";
          break;
        case "easypay_phone":
          easyPayPhoneValid = value != "";
          formErrors.easyPayPhone = easyPayPhoneValid ? "" : "IsInvalid";
          break;
        default:
          break;
      }

      if (
        this.state.receivingMethod == "bank" &&
        bankHolderValid &&
        bankIbanValid
      ) {
        receivingInfoValid = true;
      } else if (this.state.receivingMethod == "epay" && epayKinValid) {
        receivingInfoValid = true;
      } else if (
        this.state.receivingMethod == "easypay" &&
        easyPayNamesValid &&
        easyPayEgnValid &&
        easyPayPhoneValid
      ) {
        receivingInfoValid = true;
      } else if (this.state.receivingMethod == "usdt") {
        receivingInfoValid = true;
      } else {
        receivingInfoValid = false;
      }

      return this.setState(
        {
          formErrors,
          amountValid,
          priceValid,
          paymentMethodValid,
          receiverValid,
          receivingMethodValid,
          receivingInfoValid,
          bankHolderValid,
          bankIbanValid,
          epayKinValid,
          easyPayNamesValid,
          easyPayEgnValid,
          easyPayPhoneValid,
          formValid:
            amountValid &&
            priceValid &&
            paymentMethodValid &&
            receiverValid &&
            receivingMethodValid &&
            receivingInfoValid
        },
        () => {
          return resolve(true);
        }
      );
    });
  }

  getMaxSellPrice() {
    if (this.state.paymentMethod != "account") {
      if (this.state.receivingMethod == "easypay") {
        return 4900;
      }

      return this.props.config.sell_max;
    } else {
      if (
        this.state.receivingMethod == "easypay" &&
        this.getMaxPrice(this.props.account.type, this.props.account.balance) >
        4900
      ) {
        return 4900;
      }
      return this.getMaxPrice(this.props.account.type, this.props.account.balance);
    }
  }

  copyToClipboard(e) {
    this.cryptoAddress.select();
    document.execCommand("copy");
    this.setState({
      copied: true
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currency.type != this.props.currency.type) {
      if ((this.state.receivingMethod == "easypay") && this.props.currency.type != 'USDT') {
        const receivingMethod = "bank";
        this.setState({ receivingMethod }, () => {
          this.validateField("receivingMethod", receivingMethod).then(() => {
            this.recalculateTotal();
          });
        });
        this.callParentAction(receivingMethod);
      }
      if (this.props.currency.type == 'USDT') {
        const receivingMethod = "usdt";
        this.setState({ receivingMethod }, () => {
          this.validateField("receivingMethod", receivingMethod).then(() => {
            this.recalculateTotal();
          });
        });
        this.callParentAction(receivingMethod);
      }
    }
  }

  additionalFields() {
    if (this.state.receivingMethod == "bank") {
      return (
        <div>
          <div className="col-md-12 form-group">
            <div className="label-animated has-content payment-field-title">
              <div
                className="form-info-box sm-margin payments-wrapper-title"
                style={{
                  width: "82%",
                  margin: "auto",
                  textAlign: "left",
                }}
              >
                <strong>
                  <Translate id="bankData" />
                </strong>
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.bankHolderValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content payment-field-wrapper">
                {/* <label className="control-label visible-ie8 visible-ie9" style={{color: "white", marginLeft: "49px"}}>
                  <Translate id="bankHolder" />
                </label> */}
                <input
                  name="bank_holder"
                  type="text"
                  className="form-control placeholder-no-fix payment-field"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.bank_holder}
                  placeholder={this.props.activeLanguage.code === "bg" ? "Три имена" : "Card holder"}
                  style={{ marginBottom: "20px" }}
                />
                {this.state.formErrors.bankHolder != "" ? (
                  <div
                    className="help-block with-errors"
                    // style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.bankHolder${this.state.formErrors.bankHolder}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.bankIbanValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content payment-field-wrapper">
                {/* <label className="control-label visible-ie8 visible-ie9" style={{color: "white", marginLeft: "49px"}}>
                  IBAN
                </label> */}
                <input
                  name="bank_iban"
                  type="text"
                  className="form-control placeholder-no-fix payment-field"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.bank_iban}
                  placeholder="IBAN"
                />
                {this.state.formErrors.bankIban != "" ? (
                  <div
                    className="help-block with-errors"
                    // style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.bankIban${this.state.formErrors.bankIban}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.receivingMethod == "easypay") {
      return (
        <div>
          <div className="col-md-12 form-group">
            <div className="label-animated has-content payment-field-title">
              <div
                className="form-info-box sm-margin payments-wrapper-title"
                style={{
                  width: "82%",
                  margin: "auto",
                  textAlign: "left",
                }}
              >
                <strong>
                  <Translate id="easypayData" />
                </strong>
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.easyPayNamesValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content payment-field-wrapper">
                {/* <label className="control-label visible-ie8 visible-ie9" style={{color: "white", marginLeft: "49px"}}>
                  <Translate id="threeNames" />
                </label> */}
                <input
                  name="easypay_names"
                  type="text"
                  className="form-control placeholder-no-fix payment-field"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.easypay_names}
                  placeholder={this.props.activeLanguage.code === "bg" ? "Три имена" : "Full name"}

                />
                {this.state.formErrors.easyPayNames != "" ? (
                  <div
                    className="help-block with-errors"
                    // style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.names${this.state.formErrors.easyPayNames}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.easyPayEgnValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content payment-field-wrapper">
                {/* <label className="control-label visible-ie8 visible-ie9" style={{color: "white", marginLeft: "49px"}}>
                  <Translate id="egn" />
                </label> */}
                <input
                  name="easypay_egn"
                  type="text"
                  className="form-control placeholder-no-fix payment-field"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.easypay_egn}
                  placeholder={this.props.activeLanguage.code === "bg" ? "ЕГН" : "EGN"}
                />
                {this.state.formErrors.easyPayEgn != "" ? (
                  <div
                    className="help-block with-errors"
                    // style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.egn${this.state.formErrors.easyPayEgn}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.easyPayPhoneValid ? "has-error" : "has-success"
              }`}
          >
            <div className="input-icon">
              <div className="label-animated has-content payment-field-wrapper">
                {/* <label className="control-label visible-ie8 visible-ie9" style={{color: "white", marginLeft: "49px"}}>
                  <Translate id="phone" />
                </label> */}
                <input
                  name="easypay_phone"
                  type="text"
                  className="form-control placeholder-no-fix payment-field"
                  autoComplete="off"
                  onChange={this.onFieldChange.bind(this)}
                  value={this.state.easypay_phone}
                  placeholder={this.props.activeLanguage.code === "bg" ? "Телефонен номер" : "Phone number"}
                />
                {this.state.formErrors.easyPayPhone != "" ? (
                  <div
                    className="help-block with-errors"
                    // style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate
                        id={`errors.phone${this.state.formErrors.easyPayPhone}`}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.receivingMethod == "epay") {
      return (
        <div>
          <div className="col-md-12 form-group">
            <div className="label-animated has-content">
              <div
                className="form-info-box sm-margin payments-wrapper-title"
                style={{
                  width: "82%",
                  margin: "auto",
                  textAlign: "left",
                }}
              >
                <strong>
                  <Translate id="epayData" />
                </strong>
              </div>
            </div>
          </div>
          <div
            className={`col-md-12 form-group ${!this.state.epayKinValid ? "has-error" : "has-success"
              }`}
          >
            <div className="label-animated has-content">
              {/* <label className="control-label visible-ie8 visible-ie9" style={{color: "white", marginLeft: "49px"}}>
                <Translate id="epayKIN" />
              </label> */}
              <input
                name="epay_kin"
                type="text"
                className="form-control placeholder-no-fix payment-field"
                autoComplete="off"
                onChange={this.onFieldChange.bind(this)}
                value={this.state.epay_kin}
                placeholder="KIN"
              />
              {this.state.formErrors.epayKin != "" ? (
                <div
                  className="help-block with-errors"
                  // style={{ marginLeft: "16px" }}
                >
                  <span>
                    <Translate
                      id={`errors.epayKin${this.state.formErrors.epayKin}`}
                    />
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    if (this.props.auth.authenticated && this.props.user.totp == 'none') {
      this.props.setError('2FA Authenticaton Required');
      return (
        <Redirect to="/settings" />
      );
    }

    if (this.state.isLoading) {
      return <Loading />;
    } else if (!_.isEmpty(this.props.transaction)) {
      if (this.state.receivingMethod == "bank") {
        return (
          <div className="account-action-form-wrapper">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3><Translate id="selling" /> {this.props.account.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.sell" /> {this.props.account.title}!
                  </h5>
                  <p><Translate id="depositTexts.pt2" /></p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}{" "}
                  </span>
                  <p><Translate id="depositTexts.pt3" /> {this.props.account.title} wallet <Translate id="address" />: </p>
                  <span>
                    <i
                      className="fa fa-copy"
                      style={{ marginRight: "15px" }}
                      onDoubleClick={this.copyToClipboard}
                      title="Copy"
                    ></i>
                    {this.props.transaction.account_identificator}
                  </span>
                  <input
                    type="hidden"
                    ref={cryptoAddress => (this.cryptoAddress = cryptoAddress)}
                    value={this.props.transaction.account_identificator}
                  />
                  {this.state.copied ? (
                    <span>
                      <br />
                      <Translate id="copied" />
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 text-center">
                    {(['UNI', 'LINK', 'AAVE', 'USDC', 'WLD', 'COMP', 'AMP', 'MATIC', 'ARB', 'MKR', 'MASK', 'SHIB', 'PEPE', 'APE', 'SNX', 'LDO', 'CRV', 'RNDR', 'SAND', 'MANA', 'CAKE', 'FLOKI', 'MEME'].indexOf(this.props.account.type) != -1) ? (
                      <p><strong style={{ color: 'red' }} >Network: ERC-20 (ETH)</strong></p>
                    ) : ""}
                  </div>
                  <p>
                    <Translate id="afterConfirming" />{" "}
                    {JSON.parse(this.props.transaction.data).currency.type} <Translate id="through" />
                  </p>
                  <span><Translate id="bankTransfer" /></span>
                  <p><Translate id="toTheBank" />:</p>
                  <p>
                    <span>
                      {JSON.parse(this.props.transaction.data).bank_holder}
                    </span>
                  </p>
                  <span>
                    {JSON.parse(this.props.transaction.data).bank_iban}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.receivingMethod == "epay") {
        return (
          <div className="account-action-form-wrapper">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3><Translate id="selling" /> {this.props.account.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.sell" /> {this.props.account.title}!
                  </h5>
                  <p><Translate id="depositTexts.pt2" /></p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}{" "}
                  </span>
                  <p><Translate id="depositTexts.pt3" /> {this.props.account.title} wallet <Translate id="address" />: </p>
                  <span>
                    <i
                      className="fa fa-copy"
                      style={{ marginRight: "15px" }}
                      onDoubleClick={this.copyToClipboard}
                      title="Copy"
                    ></i>
                    {this.props.transaction.account_identificator}
                  </span>
                  <input
                    type="hidden"
                    ref={cryptoAddress => (this.cryptoAddress = cryptoAddress)}
                    value={this.props.transaction.account_identificator}
                  />
                  {this.state.copied ? (
                    <span>
                      <br />
                      <Translate id="copied" />
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 text-center">
                    {(['UNI', 'LINK', 'AAVE', 'USDC', 'WLD', 'COMP', 'AMP', 'MATIC', 'ARB', 'MKR', 'MASK', 'SHIB', 'PEPE', 'APE', 'SNX', 'LDO', 'CRV', 'RNDR', 'SAND', 'MANA', 'CAKE', 'FLOKI', 'MEME'].indexOf(this.props.account.type) != -1) ? (
                      <p><strong style={{ color: 'red' }} >Network: ERC-20 (ETH)</strong></p>
                    ) : ""}
                  </div>
                  <p>
                    <Translate id="afterConfirming" />{" "}
                    {JSON.parse(this.props.transaction.data).currency.type} в
                  </p>
                  <span>еPay</span>
                  <p><Translate id="toTheKin" />:</p>
                  <span>
                    {JSON.parse(this.props.transaction.data).epay_kin}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.receivingMethod == "easypay") {
        return (
          <div className="account-action-form-wrapper">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3><Translate id="selling" /> {this.props.account.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.sell" /> {this.props.account.title}!
                  </h5>
                  <p><Translate id="depositTexts.pt2" /></p>
                  <span>
                    {parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)}{" "}
                    {this.props.transaction.account_type}{" "}
                  </span>
                  <p><Translate id="depositTexts.pt3" /> {this.props.account.title} wallet <Translate id="address" />: </p>
                  <span>
                    <i
                      className="fa fa-copy"
                      style={{ marginRight: "15px" }}
                      onDoubleClick={this.copyToClipboard}
                      title="Copy"
                    ></i>
                    {this.props.transaction.account_identificator}
                  </span>
                  <input
                    type="hidden"
                    ref={cryptoAddress => (this.cryptoAddress = cryptoAddress)}
                    value={this.props.transaction.account_identificator}
                  />
                  {this.state.copied ? (
                    <span>
                      <br />
                      <Translate id="copied" />
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 text-center">
                    {(['UNI', 'LINK', 'AAVE', 'USDC', 'WLD', 'COMP', 'AMP', 'MATIC', 'ARB', 'MKR', 'MASK', 'SHIB', 'PEPE', 'APE', 'SNX', 'LDO', 'CRV', 'RNDR', 'SAND', 'MANA', 'CAKE', 'FLOKI', 'MEME'].indexOf(this.props.account.type) != -1) ? (
                      <p><strong style={{ color: 'red' }} >Network: ERC-20 (ETH)</strong></p>
                    ) : ""}
                  </div>
                  <p>
                    <Translate id="afterConfirming" />{" "}
                    {JSON.parse(this.props.transaction.data).currency.type} <Translate id="atOffice" />
                  </p>
                  <span>EasyPay</span>
                  <p><Translate id="byProvidingEGN" /></p>
                  <span>
                    {JSON.parse(
                      this.props.transaction.data
                    ).easypay_egn.substring(
                      0,
                      JSON.parse(this.props.transaction.data).easypay_egn
                        .length - 4
                    )}
                    ****
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.receivingMethod == "usdt") {
        return (
          <div className="account-action-form-wrapper">
            <div className="row">
              <div className="col-12">
                <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                  <h3><Translate id="selling" /> {this.props.account.title}!</h3>
                </header>
              </div>
              <div className="col-md-offset-2 col-md-8">
                <div className="success-msg-box">
                  <figure>
                    <i className="far fa-check-circle"></i>
                  </figure>
                  <h5>
                    <Translate id="success.sell" /> {this.props.account.title}!
                  </h5>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div />;
      }
    } else if (!_.isEmpty(this.props.account)) {
      return (
        <div className="account-action-form-wrapper">
          <div className="col-md-12">
            <form onSubmit={this.onSubmit} className="account-action-form">
              <div style={{ textAlign: "center" }}>
                <AssetRatesNew
                  account={this.props.account}
                  action={this.onCalculatorChange}
                  diff={`${this.props.config.sell_percent - this.state.rateSub}`}
                  sign="-"
                  minTotal={this.props.config.sell_min}
                  //   this.state.paymentMethod != "account"
                  //     ? this.props.config.sell_min
                  //     : this.props.account.balance > 0
                  //       ? this.getPrice(this.props.account.type, 0.001)
                  //       : 0
                  // }
                  maxTotal={this.getMaxSellPrice()}
                />
                {/* <div className={`col-md-6 ${this.state.promoCodeUse ? "d-none" : ""}`} >
                <div className="form-group">
                  <div className="input-icon">
                    <div className="label-animated has-content">
                      <input
                        type="text"
                        className="form-control placeholder-no-fix touched"
                        autoComplete="off"
                        value={this.state.promoCode}
                        onChange={this.onPromoCodeChange.bind(this)}
                        name="promocode"
                      />
                      <label className="control-label visible-ie8 visible-ie9">
                        <Translate id="promoCode" />
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.formErrors.promoCode != "" ? (
                  <div
                    className="help-block with-errors"
                    style={{ marginLeft: "16px" }}
                  >
                    <span>
                      <Translate id="errors.promoCodeIsInvalid" />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={`col-md-6 ${this.state.promoCodeUse ? "d-none" : ""}`}>
                <button
                  type="button"
                  className="btn btn-action btn-rounded crypto-button crypto-sell"
                  onClick={this.onPromoCodeSubmit.bind(this)}
                >
                  <Translate id="apply" />
                </button>
              </div>
              <div className={`col-md-12 ${!this.state.promoCodeUse ? "d-none" : ""}`} >
                <div className="form-info-box">
                  <strong><Translate id="promoCodeUsed" /></strong>
                </div>
              </div> */}
                <div className="col-md-12 form-group">
                  <div
                    className="form-info-box sm-margin payments-wrapper-title"
                    style={{
                      width: "82%",
                      margin: "auto",
                      textAlign: "left",
                    }}
                  >
                    <strong>
                      <Translate id="payment" />
                    </strong>
                  </div>
                  <div className="payments-wrapper-new">
                    {_.map(paymentMethods, (title, method) => {
                      if (method == "account" && this.props.account.balance > 0) {
                        return (
                          <label className="custom-check-element new-custom-check-payment-element" key={method} >
                            <input
                              type="radio"
                              onChange={this.onPaymentMethodChange.bind(this)}
                              value={method}
                              checked={this.state.paymentMethod === method}
                              name="payments"
                            />
                            <i></i>
                            <Translate id={`payments.${title}`} />
                          </label>
                        );
                      } else if (method != "account" && this.state.receivingMethod != 'usdt') {
                        return (
                          <label className="custom-check-element new-custom-check-payment-element" key={method}>
                            <input
                              type="radio"
                              onChange={this.onPaymentMethodChange.bind(this)}
                              value={method}
                              checked={this.state.paymentMethod === method}
                              name="payments"
                            />
                            <i></i>
                            <Translate id={`payments.${title}`} />
                          </label>
                        );
                      }
                    })}
                  </div>
                  <div
                    className="label-animated notice-new buy-notice"
                    style={{ margin: "20px 0px 50px 50px" }}
                  >
                    <label>
                      <Translate id="depositCryptoSell" />
                    </label>
                  </div>
                </div>

                <div className="col-md-12 form-group">
                  <div
                    className="form-info-box sm-margin payments-wrapper-title"
                    style={{
                      width: "82%",
                      margin: "auto",
                      textAlign: "left",
                    }}
                  >
                    <strong>
                      <Translate id="selectPaymentReceiveOption" />
                    </strong>
                  </div>
                  <div className="payments-wrapper-new">
                    {_.map(receivingMethods, (title, method) => {
                      if (
                        (method == "easypay" && this.props.currency.type == "EUR") ||
                        (method == "easypay" && (this.props.activeLanguage.code == 'ro' || this.props.currency.type == "RON")) ||
                        (method == "usdt" && this.props.account.type == "USDT") ||
                        (this.props.currency.type == "USDT" && this.props.account.type != "USDT" && method != "usdt")
                      ) {
                        return;
                      } else {
                        return (
                          <label className="custom-check-element new-custom-check-payment-element" key={method}>
                            <input
                              name="receivingMethod"
                              type="radio"
                              onChange={this.onReceivingMethodChange.bind(this)}
                              value={method}
                              checked={this.state.receivingMethod === method}
                            />
                            <i></i>
                            <img src={receivingMethodsIcons[method]} />
                            <Translate id={`receivers.${title}`} />
                          </label>
                        );
                      }
                    })}
                  </div>
                  {this.state.formErrors.receivingMethod != "" ? (
                    <div
                      className="help-block with-errors"
                      // style={{ marginLeft: "16px" }}
                    >
                      <span>
                        <Translate id="errors.receivingMethodIsInvalid" />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {this.additionalFields()}
                <div className="col-md-12 promocode-wrapper-new" style={{ marginTop: "30px" }}>
                  <div className={` ${this.state.promoCodeUse ? "d-none" : ""}`} style={{ width: "100%" }}>
                    <div className="form-group" style={{marginBottom: "50px"}}>
                      <div className="input-icon">
                        <div className="label-animated has-content payment-field-wrapper">
                          <input
                            type="text"
                            className="form-control placeholder-no-fix touched payment-field"
                            autoComplete="off"
                            value={this.state.promoCode}
                            onChange={this.onPromoCodeChange.bind(this)}
                            name="promocode"
                            placeholder={this.props.activeLanguage.code === "bg" ? "Промокод" : "Promocode"}
                            style={{fontSize: "15px"}}
                          />
                          {/* <label className="control-label visible-ie8 visible-ie9" style={{ marginLeft: "49px" }}>
                            <Translate id="promoCode" />
                          </label> */}
                        </div>
                      </div>
                      {this.state.formErrors.promoCode != "" ? (
                        <div
                          className="help-block with-errors"
                        >
                          <span>
                            <Translate id="errors.promoCodeIsInvalid" />
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* {this.state.formErrors.promoCode != "" ? (
                      <div
                        className="help-block with-errors"
                        style={{ marginLeft: "16px" }}
                      >
                        <span>
                          <Translate id="errors.promoCodeIsInvalid" />
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className={`${this.state.promoCodeUse ? "d-none" : ""}`}>
                    <button
                      type="button"
                      className="apply-promocode-button-new"
                      // style={{
                      //   position: "absolute",
                      //   backgroundColor: "transparent",
                      //   border: "none",
                      //   borderLeft: "1px solid grey",
                      //   padding: "5px 10px",
                      //   left: "80%",
                      //   top: "12px",
                      //   zIndex: "2"
                      // }}
                      onClick={this.onPromoCodeSubmit.bind(this)}
                    >
                      <Translate id="apply" />
                    </button>
                  </div>
                  <div className={`col-md-12 ${!this.state.promoCodeUse ? "d-none" : ""}`} >
                    <div className="form-info-box">
                      <strong><Translate id="promoCodeUsed" /></strong>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-action btn-rounded crypto-button crypto-buy"
                    disabled={!this.state.formValid}
                  >
                    <Translate id="sell" /> <Translate id="for" />{" "}
                    {formatMoney(this.state.total)}{" "}
                    <Translate id={this.props.currency.symbol} />
                  </button>
                </div>
              </div>
              <div className="col-md-12 text-center mt-5">
                {(['UNI', 'LINK', 'AAVE', 'USDC', 'WLD', 'COMP', 'AMP', 'MATIC', 'ARB', 'MKR', 'MASK', 'SHIB', 'PEPE', 'APE', 'SNX', 'LDO', 'CRV', 'RNDR', 'SAND', 'MANA', 'CAKE', 'FLOKI', 'MEME'].indexOf(this.props.account.type) != -1) ? (
                  <p><strong style={{ color: 'red' }} >Network: ERC-20 (ETH)</strong></p>
                ) : ""}
              </div>
              <div className="clearfix"></div>
            </form>
            <br />
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps({ transaction, user, auth, config, rates, currency }) {
  return { transaction, user, auth, config, rates, currency };
}

export default connect(mapStateToProps, {
  fetchTransaction,
  sendTransaction,
  clearTransaction,
  validatePromoCode,
  setError
})(withLocalize(AccountSellFormNew));
