import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import { fetchAccounts } from "../../actions";
import Loading from "../Loading";

class AccountsBalances extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchAccounts();
  }

  getRate(assetType, assetAmount) {
    let rate = 0;
    _.map(this.props.rates, (aRate, i) => {
      let tempRate = parseFloat(aRate.rate / this.props.currency.rate);
      if (assetType == 'USDT' && this.props.currency.type == 'USDT') {
        rate = parseFloat(assetAmount);
      } else if (aRate.type == assetType) {
        rate = parseFloat(assetAmount * tempRate);
      }
    });

    return rate.toFixed(2);
  }

  getRateId(assetType) {
    let result = 0;

    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.id;
        return;
      }
    });

    return result;
  };

  getRateOrderPos(assetType) {
    let result = 0;

    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.order_pos;
        return;
      }
    });

    return result;
  };

  getRateTradeDisabled(assetType) {
    let result = false;

    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.trade_disabled;
        return;
      }
    });

    return result;
  };

  isTradeDisabled(assetType) {
    let result = false;

    _.map(this.props.rates, (itemRate, i) => {
      if (itemRate.type == assetType) {
        result = itemRate.trade_disabled;
        return;
      }
    });

    return result;
  };

  render() {
    let counter = 0;

    if (!_.isEmpty(this.props.accounts)) {

      const sortedAccounts = _.orderBy(this.props.accounts, [
        account => parseFloat(this.getRate(account.type, account.balance)),
        account => parseFloat(this.getRateOrderPos(account.type)),
        account => parseFloat(this.getRateTradeDisabled(account.type)),
        account => parseFloat(this.getRateId(account.type))
      ], ['desc', 'asc', 'asc', 'asc']);

      return (
        <React.Fragment>
          {_.map(sortedAccounts, account => {
            const trade_disabled = this.isTradeDisabled(account.type);
            let color = "";
            // if (account.type == "BTC" || account.type == "SHIB" || account.type == "MANA" || account.type == "LDO") color = "orange";
            // else if (account.type == "ETH" || account.type == "COMP") color = "purple";
            // else if (account.type == "BCH" || account.type == "PEPE") color = "dark-green";
            // else if (account.type == "XRP" || account.type == "MEME" || account.type == "MATIC") color = "blue";
            // else if (account.type == "RVN" || account.type == "SAND" || account.type == "MASK" || account.type == "APE" || account.type == "CAKE") color = "blue-2";
            // else if (account.type == "ETC" || account.type == "MKR") color = "light-green";
            // else if (account.type == "UNI" || account.type == "AMP") color = "purple-2";
            // else if (account.type == "LINK" || account.type == "USDC" || account.type == "ARB" || account.type == "SNX") color = "blue-3";
            // else if (account.type == "AAVE" || account.type == "CRV") color = "light-oil";

            if (trade_disabled) {
              color = "disabled";
            }

            return (
              <div className="col-lg-4 col-md-6" key={++counter}>
                <div className={`infoFigureBox links-out ${color}-box`}>
                  <figure>
                    <Link to={`/accounts/${account.type}`}>
                      <img
                        src={`/img/assets/${account.type}.png`.toLowerCase()}
                        alt={account.type}
                      />
                    </Link>
                  </figure>
                  <div className="text">
                    {this.props.activeLanguage && this.props.activeLanguage.code == 'ro' ? (
                      <p>
                        <Translate id="balance" /> {account.title}
                      </p>
                    ) : (
                      <p>
                        {account.title} <Translate id="balance" />
                      </p>
                    )}
                    <strong>
                      {parseFloat(account.balance).toFixed(account.decimals)}{" "}
                      {account.type}
                    </strong>
                    <span>
                      {this.getRate(account.type, account.balance)}{" "}
                      <Translate id={this.props.currency.symbol} />
                    </span>
                  </div>
                </div>
                <div className="infoFigure-links">
                  <div className="row">
                    {!trade_disabled && (
                      <div className="col-lg-6">
                        <Link
                          to={`/accounts/${account.type}/buy`}
                          title=""
                          className="btn reverse"
                        >
                          <Translate id="buy" />
                        </Link>
                      </div>
                    )}

                    <div className="col-lg-6">
                      <Link
                        to={`/accounts/${account.type}/sell`}
                        title=""
                        className="btn"
                      >
                        <Translate id="sell" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      );
    } else if (_.isObject(this.props.accounts)) {
      return <div></div>;
    } else {
      return <Loading />;
    }
  }
}

function mapStateToProps({ accounts, rates, currency }) {
  return { accounts, rates, currency };
}

export default connect(
  mapStateToProps,
  { fetchAccounts }
)(withLocalize(AccountsBalances));
