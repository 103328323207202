import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import AssetRates from '../assets/AssetRates';
import Loading from '../Loading';
import { fetchTransaction, sendTransaction, clearTransaction, sendTotp, verifyTotp, setError } from '../../actions';
import { formatMoney, formatCrypto } from '../../utilities/Format';
import { addressRegExes, amountRegExes } from '../../utilities/RegEx';

const paymentMethods = {
    account: 'account'
}

const receivingMethods = {
    address: 'address',
    binance: 'binance',
}

class AccountWithdrawForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: 0.00,
            price: 0.00,
            fees: 0.00,
            total: 0.00,
            rate: 0.00,
            paymentMethod: 'account',
            receiver: '',
            receivingMethod: 'address',
            ethType: 'eth',
            totp: '',
            formValid: false,
            formErrors: {
                amount: '',
                price: '',
                paymentMethod: '',
                receiver: '',
                receivingMethod: '',
                totp: ''
            },
            amountValid: false,
            priceValid: false,
            paymentMethodValid: true,
            receivingMethodValid: true,
            receiverValid: false,
            totpValid: true,
            errors: [],
            attemptVerifyTotp: false
        }

        this.onCalculatorChange = this.onCalculatorChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onTotpSubmit = this.onTotpSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            receiver: (props.account.title) ? props.user[`${props.account.title.toLowerCase()}_address`] : ''
        })
    }

    componentWillUnmount() {
        this.props.clearTransaction();
    }

    onCalculatorChange(amount, price, field, rate) {
        this.setState({ amount, price, rate }, () => {
            return this.validateField(field, (field === 'amount') ? amount : price).then((resp) => {
                return this.validateField(
                    (field === 'amount') ? 'price' : 'amount',
                    (field === 'amount') ? price : amount
                ).then((resp) => {
                    this.recalculateTotal();
                });
            });
        });
    }

    onPaymentMethodChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ paymentMethod: value }, () => {
            this.validateField(name, value).then(() => this.recalculateTotal());
        });
    }

    onReceivingMethodChange(event) {
        let name = "receivingMethod";
        let value = event.target.value;
        if (value === "binance") {
            this.setState(
                {
                    receiver: '',
                    receivingMethod: value,
                    formValid: false
                },
                () => {
                    this.validateField(name, value);
                }
            );
        } else {
            this.setState(
                {
                    receiver: this.props.user[
                        this.props.account.title.toLowerCase() + "_address"
                    ],
                    receivingMethod: value,
                    formValid: false
                },
                () => {
                    this.validateField(name, value);
                }
            );
        }
    }

    onReceiverChange(event) {
        let name = 'receiver';
        let value = event.target.value;
        this.setState({ receiver: value }, () => {
            this.validateField(name, value);
        });
    }

    onEthTypeChange(event) {
        let name = 'ethType';
        let value = event.target.value;
        this.setState({ ethType: value }, () => {
            this.validateField(name, value);
        });
    }

    onTotpChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ totp: value }, () => {
            this.validateField(name, value);
        });
    }

    recalculateTotal() {
        let total = 0.00;
        let price = parseFloat(this.state.price);

        total = price;
        this.setState({
            total
        });
    }

    getPrice(type, amount, action) {
        let price = 0;
        _.map(this.props.rates, (aRate, i) => {
            let tempRate = aRate.rate;
            tempRate = parseFloat(aRate.rate / this.props.currency.rate);
            if (this.props.currency.type == 'USDT') {
                tempRate = aRate.rate;
            }

            if (aRate.type == type && action == 'amount') {
                price = parseFloat(amount * tempRate).toFixed(2);
            } else if (aRate.type == this.props.account.type && action == 'price') {
                price = parseFloat(amount / tempRate).toFixed(this.props.account.decimals);
            }
        });
        return price;
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.formValid) {
            if (this.props.user.totp == 'none') {
                this.props.sendTransaction({ ...this.state, account: this.props.account, type: 'WITHDRAW', currency: this.props.currency });
            } else {
                this.props.sendTotp(this.props.user.totp);
                this.setState({
                    attemptVerifyTotp: true,
                    totpValid: false,
                    formValid: false
                });
            }
        }
    }

    onTotpSubmit(event) {
        event.preventDefault();
        if (this.state.formValid) {
            this.props.verifyTotp({ totp: this.state.totp }).then((success) => {
                if (success && this.state.formValid) {
                    this.props.sendTransaction({ ...this.state, account: this.props.account, type: 'WITHDRAW', currency: this.props.currency });
                } else {
                    this.setState({ errors: ['totpIsInvalid'] });
                }
            });
        }
    }

    validateField(fieldName, value) {
        return new Promise((resolve) => {
            let formErrors = this.state.formErrors;
            let amountValid = this.state.amountValid;
            let priceValid = this.state.priceValid;
            let paymentMethodValid = this.state.paymentMethodValid;
            let receiverValid = this.state.receiverValid;
            let receivingMethodValid = this.state.receivingMethodValid;
            let totpValid = this.state.totpValid;

            switch (fieldName) {
                case "amount":
                    amountValid =
                        parseFloat(value).toPrecision(9) != "0.00000000" &&
                        value.match(amountRegExes.crypto);
                    formErrors.amount = amountValid ? "" : "IsInvalid";
                    if (parseFloat(value) < parseFloat(this.props.config['buy_min_' + this.props.account.type])) {
                        formErrors.amount = "IsBelowMin";
                    }
                    amountValid = !formErrors.amount.length;
                    break;
                case 'price':
                    formErrors.price = value && value.match(amountRegExes.fiat) ? '' : 'IsInvalid';
                    if (value * 1 < this.getPrice(this.props.account.type, (this.props.account.balance > 0 ? 0.001 : 0), 'amount')) {
                        formErrors.price = 'IsBelowMin';
                    } else if (value * 1 > this.getPrice(this.props.account.type, this.props.account.balance, 'amount')) {
                        formErrors.price = 'IsAboveMax';
                    }
                    priceValid = !formErrors.price.length;
                    break;
                case "receiver":
                    if (this.state.receivingMethod == 'address') {
                        receiverValid = value.match(addressRegExes[this.props.account.type]);
                    } else if (value.trim() != '') {
                        receiverValid = true;
                    } else if (value.trim() == '') {
                        receiverValid = false;
                    }
                    formErrors.receiver = receiverValid ? "" : "IsInvalid";
                    break;
                case "receivingMethod":
                    receivingMethodValid = receivingMethods[value].length > -1;
                    formErrors.receivingMethod = receivingMethodValid ? "" : "IsInvalid";
                    break;
                case 'paymentMethod':
                    paymentMethodValid = paymentMethods[value].length > -1;
                    formErrors.paymentMethod = paymentMethodValid ? '' : 'IsInvalid';
                    break;
                case 'totp':
                    totpValid = value.length == 6;
                    formErrors.totp = totpValid ? '' : 'IsInvalid';
                    break;
                default:
                    break;
            }
            return this.setState({
                formErrors,
                amountValid,
                priceValid,
                paymentMethodValid,
                receiverValid,
                receivingMethodValid,
                totpValid,
                formValid: amountValid &&
                    priceValid &&
                    paymentMethodValid &&
                    receiverValid &&
                    receivingMethodValid &&
                    totpValid
            }, () => {
                return resolve(true);
            });

        });
    }

    totpActions() {
        if (this.props.user.totp === 'google') {
            return <p><Translate id="insertCode" /> <strong>Google Authenticator</strong></p>
        } else if (this.props.user.totp === 'email') {
            return <p><Translate id="insertCode" /> <strong>E-mail</strong></p>
        } else if (this.props.user.totp === 'sms') {
            return <p><Translate id="insertCode" /> <strong>SMS</strong></p>
        }
    }

    renderAlert() {
        if (this.state.errors.length) {
            return (
                <div className={`col-md-offset-3 col-md-6 col-xs-offset-2 col-xs-8 form-group`}>
                    <div className="alert alert-danger">
                        <strong><Translate id="oops" />! </strong>
                        {_.map(this.state.errors, (error) => {
                            return <Translate id={`errors.${error}`} key={error} />;
                        })}
                    </div>
                </div>
            );
        }
    }

    render() {
        if (this.props.auth.authenticated && this.props.user.totp == 'none') {
            this.props.setError('2FA Authenticaton Required');
            return (
                <Redirect to="/settings" />
            );
        }

        if (!_.isEmpty(this.props.transaction)) {
            return (
                <div className="infoFigureBox">
                    <div className="row">
                        <div className="col-12">
                            <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                                <h3><Translate id="withdrawing" /> {this.props.account.title}!</h3>
                            </header>
                        </div>
                        <div className="col-md-offset-2 col-md-8">
                            <div className="success-msg-box">
                                <figure><i className="far fa-check-circle"></i></figure>
                                <h5><Translate id="success.withdraw" /> {this.props.account.title}!</h5>
                                <p><Translate id="asSoonWithdraw" /></p>
                                <span>{parseFloat(this.props.transaction.amount).toFixed(this.props.account.decimals)} {this.props.transaction.account_type} </span>
                                {this.state.receivingMethod === "binance" ? (
                                    <p>
                                        Binance:<br />
                                        <span>
                                            {JSON.parse(this.props.transaction.data).receiver}
                                        </span>
                                    </p>
                                ) : ""}
                                {this.state.receivingMethod === "address" ? (
                                    <p>
                                        <Translate id="toTheWallet" /> {this.props.account.title} wallet <Translate id="address" />:<br />
                                        <span>
                                            {JSON.parse(this.props.transaction.data).receiver}
                                        </span>
                                    </p>
                                ) : ""}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.attemptVerifyTotp) {
            return (
                <div className="infoFigureBox">
                    <div className="row">
                        <div className="col-12">
                            <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                                <h3><Translate id="withdrawing" /> {this.props.account.title}!</h3>
                            </header>
                        </div>
                        <form onSubmit={this.onTotpSubmit}>
                            <div className={`col-md-offset-3 col-md-6 col-xs-offset-2 col-xs-8 form-group`}>
                                {this.totpActions()}
                            </div>
                            <div className={`col-md-offset-3 col-md-6 col-xs-offset-2 col-xs-8 form-group ${!this.state.totpValid ? 'has-error' : 'has-success'}`}>
                                <div className="input-icon">
                                    <div className="label-animated has-content">
                                        <label className="control-label visible-ie8 visible-ie9"><Translate id="2FASettings.totpCode" /></label>
                                        <input
                                            type="text"
                                            className="form-control placeholder-no-fix"
                                            autoComplete="off"
                                            onChange={this.onTotpChange.bind(this)}
                                            value={this.state.totp}
                                            name="totp"
                                        />
                                    </div>
                                </div>
                                {(this.state.formErrors.totp != '') ? (
                                    <div className="help-block with-errors" style={{ marginLeft: '16px' }}>
                                        <span><Translate id="errors.totpIsInvalid" /></span>
                                    </div>
                                ) : ''}
                            </div>
                            {this.renderAlert()}
                            <div className="col-md-offset-3 col-md-6 col-xs-offset-2 col-xs-8 text-center">
                                <button type="submit" className="btn btn-action" style={{ marginTop: '0px' }} disabled={!this.state.formValid}>
                                    <Translate id="send" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else if (!_.isEmpty(this.props.account)) {
            return (
                <div className="infoFigureBox">
                    <div className="col-md-7">
                        <form onSubmit={this.onSubmit}>
                            <AssetRates
                                account={this.props.account}
                                diff="0"
                                sign="-"
                                action={this.onCalculatorChange}
                                minTotal={this.getPrice(this.props.account.type, (this.props.account.balance > 0 ? 0.001 : 0), 'price')}
                                maxTotal={this.getPrice(this.props.account.type, this.props.account.balance, 'amount')}
                                minAmount={this.props.config['buy_min_' + this.props.account.type]}
                                maxAmount={this.props.account.balance}
                            />
                            <div className="bold-forms">
                                <div className={`col-md-12 form-group ${!this.state.paymentMethodValid ? 'has-error' : 'has-success'}`}>
                                    <div className="form-info-box sm-margin">
                                        <strong><Translate id="detailsWithdraw" /></strong>
                                    </div>
                                    {_.map(paymentMethods, (title, method) => {
                                        return (
                                            <label className="custom-check-element" key={method}>
                                                <input
                                                    name="paymentMethod"
                                                    type="radio"
                                                    onChange={this.onPaymentMethodChange.bind(this)}
                                                    value={method}
                                                    checked={this.state.paymentMethod === method}
                                                />
                                                <i></i>
                                                <Translate id={`payments.${title}`} />
                                            </label>
                                        );
                                    })}
                                    {(this.state.formErrors.paymentMethod != '') ? (
                                        <div className="help-block with-errors" style={{ marginLeft: '16px' }}>
                                            <span><Translate id="errors.paymentIsInvalid" /></span>
                                        </div>
                                    ) : ''}
                                </div>
                                <div
                                    className={`col-md-12 form-group ${!this.state.receivingMethodValid ? "has-error" : "has-success"
                                        }`}
                                >
                                    <div className="form-info-box sm-margin">
                                        <strong>
                                            <Translate id="fillDetailsReceipt" />
                                        </strong>
                                    </div>
                                    {_.map(receivingMethods, (title, method) => {
                                        return (
                                            <label className="custom-check-element" key={method}>
                                                <input
                                                    name="receivingMethod"
                                                    type="radio"
                                                    onChange={this.onReceivingMethodChange.bind(this)}
                                                    value={method}
                                                    checked={this.state.receivingMethod === method}
                                                />
                                                <i></i>
                                                <Translate id={`receivers.${title}`} />
                                            </label>
                                        );
                                    })}
                                    {this.state.formErrors.receivingMethod != "" ? (
                                        <div
                                            className="help-block with-errors"
                                            // style={{ marginLeft: "16px" }}
                                        >
                                            <span>
                                                <Translate id="errors.receivingMethodIsInvalid" />
                                            </span>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {this.state.receivingMethod === "address" ? (
                                    <div className={`col-md-12 form-group ${!this.state.receiverValid ? 'has-error' : 'has-success'}`}>
                                        <div className="input-icon">
                                            <div className="label-animated has-content">
                                                <label className="control-label visible-ie8 visible-ie9"><Translate id="receiver" /></label>
                                                {(['USDT', 'ETH'].indexOf(this.props.account.type) != -1) ? (
                                                    <div>
                                                        <p style={{ color: 'red', marginLeft: '15px' }} >ERC-20 (ETH) or TRC-20 (TRON) address</p>
                                                    </div>
                                                ) : ""}
                                                <input
                                                    type="text"
                                                    className="form-control placeholder-no-fix"
                                                    autoComplete="off"
                                                    onChange={this.onReceiverChange.bind(this)}
                                                    value={this.state.receiver}
                                                />
                                            </div>
                                        </div>
                                        <div className="label-animated" style={{ marginBottom: '50px' }}>
                                            <label>
                                                <Translate id="chechAddressIsCorrect" />
                                            </label>
                                        </div>

                                        {this.props.account.identificator.split(":").length > 1 &&
                                            <div>
                                                <p style={{ color: "red" }}> <Translate id="withdraw-memo-required" /></p>
                                            </div>}

                                        {(this.state.formErrors.receiver != '') ? (
                                            <div className="help-block with-errors" style={{ marginLeft: '16px' }}>
                                                <span><Translate id="errors.receiverIsInvalid" /></span>
                                            </div>
                                        ) : ''}
                                    </div>
                                ) : ""}
                                {this.state.receivingMethod === "binance" ? (
                                    <div
                                        className={`col-md-12 form-group ${!this.state.receiverValid ? "has-error" : "has-success"
                                            }`}
                                    >
                                        <div className="custom-style-select no-border label-animated has-content">
                                            <div className="form-info-box sm-margin">
                                                <strong>
                                                    <Translate id="receiving" /> <Translate id="in" /> <Translate id="binance" />
                                                </strong>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control placeholder-no-fix"
                                                autoComplete="off"
                                                onChange={this.onReceiverChange.bind(this)}
                                                value={this.state.receiver}
                                                name="receiver"
                                            />
                                        </div>
                                        <div
                                            className="label-animated"
                                            style={{ marginBottom: "50px" }}
                                        >
                                            <label>
                                                <Translate id="checkBinanceIsCorrect" />
                                            </label>
                                        </div>
                                        {this.state.formErrors.receiver != "" ? (
                                            <div
                                                className="help-block with-errors"
                                                // style={{ marginLeft: "16px" }}
                                            >
                                                <span>
                                                    <Translate id="errors.receiverIsInvalid" />
                                                </span>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    <div />
                                )}

                            </div>
                            {this.state.receivingMethod === "address" && (['USDT', 'ETH'].indexOf(this.props.account.type) != -1) ? (
                                <div className="bold-forms">
                                    <div className={`col-md-12`}>
                                        <p className="pr-4 pl-4">Моля потвърдете мрежата на адреса която сте поставили</p>
                                        <div className='form-group'>
                                            <div className="custom-check-holder mt-0 mb-0">
                                                <label className="custom-check" >
                                                    <input
                                                        type="radio"
                                                        name="eth_type"
                                                        className='form-control'
                                                        onChange={this.onEthTypeChange.bind(this)}
                                                        value="eth"
                                                        required />
                                                    <i></i>
                                                    ERC-20 (ETH)
                                                </label>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className="custom-check-holder mt-0 mb-0">
                                                <label className="custom-check" >
                                                    <input
                                                        type="radio"
                                                        name="eth_type"
                                                        className='form-control'
                                                        onChange={this.onEthTypeChange.bind(this)}
                                                        value="tron"
                                                        required />
                                                    <i></i>
                                                    TRC-20 (TRON)
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : ""}
                            <div className="col-md-12 text-center">
                                <button type="submit" className="btn btn-action btn-rounded crypto-button crypto-deposit" disabled={!this.state.formValid}>
                                    <Translate id="withdraw" /> {formatCrypto(this.state.amount)} {this.props.account.type}
                                </button>
                            </div>
                            <div className="col-md-12 text-center mt-5">
                                {(['UNI', 'LINK', 'AAVE', 'USDC', 'WLD', 'COMP', 'AMP', 'MATIC', 'ARB', 'MKR', 'MASK', 'SHIB', 'PEPE', 'APE', 'SNX', 'LDO', 'CRV', 'RNDR', 'SAND', 'MANA', 'CAKE', 'FLOKI', 'MEME'].indexOf(this.props.account.type) != -1) ? (
                                    <p><strong style={{ color: 'red' }} >Network: ERC-20 (ETH)</strong></p>
                                ) : ""}
                            </div>
                            <div className="clearfix"></div>
                        </form>
                        <br />
                    </div>
                    <div className="col-md-5">
                        <header className="element-section-title has-bgr-element text-center crypto-sell-color">
                            <h3><Translate id="you.withdraw" /></h3>
                        </header>
                        <div className="payment-info-box">
                            <div className="payment-info-section">
                                <figure>
                                    <img className="fig-orig" src={`/img/assets/${this.props.account.type.toLowerCase()}.png`} title="" />
                                    <img className="fig-fade" src={`/img/assets/${this.props.account.type.toLowerCase()}.png`} title="" />
                                </figure>
                                <strong>{formatCrypto(this.state.amount)} <span>{this.props.account.type}</span></strong>
                                <p><Translate id="for" />  <span>{formatMoney(this.state.price)} <Translate id={this.props.currency.symbol} /></span></p>
                            </div>
                            <div className="payment-info-section">
                                <p><strong><Translate id="you.send" /> <Translate id="through" /></strong> <em><Translate id={`payments.${this.state.paymentMethod}`} /></em></p>
                                <i className={`fab fa-${this.props.account.title.toLowerCase()}`}></i>
                            </div>
                            <div className="payment-info-section">
                                <p>
                                    <strong>
                                        <Translate id="you.get" /> <Translate id="in" />
                                    </strong>
                                    <em>
                                        {this.state.receivingMethod === "account" ? (
                                            <Translate id="yours" />
                                        ) : (
                                            <span></span>
                                        )}{" "}
                                        {this.props.account.title}{" "}
                                        <Translate id={`receivers.${this.state.receivingMethod}`} />
                                    </em>
                                </p>
                                {this.state.receivingMethod !== "account" ? (
                                    <p>
                                        <em>{this.state.receiver}</em>
                                    </p>
                                ) : (
                                    <span />
                                )}
                                <i
                                    className={`fab fa-${this.props.account.title.toLowerCase()}`}
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }
};

function mapStateToProps({ transaction, user, auth, rates, config, currency }) {
    return { transaction, user, auth, rates, config, currency };
}

export default connect(mapStateToProps, {
    fetchTransaction,
    sendTransaction,
    clearTransaction,
    sendTotp,
    verifyTotp,
    setError
})(AccountWithdrawForm);
