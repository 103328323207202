import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { fetchUser } from '../../actions';
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebar from '../../components/users/UserSidebar';
import AccountsBalances from '../../components/accounts/AccountsBalances';
import UserHeader from '../../components/users/UserHeader';
import AssetsWidget from '../../components/assets/AssetsWidget';

class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    head() {
        return (
            <Helmet>
                <title>{`Профил - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Профил - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidMount() {
        if (typeof window != 'undefined') {
            window.scrollTo(0, 0);
        }

        this.props.fetchUser();
    }

    copyToClipboard(e) {
        this.defiCode.select();
        document.execCommand("copy");
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.head()}

                    <div className="col-12 col-lg-10 col-md-9 order-cols-right">
                        <div className="profile-page-cont profile-cols-height">
                            <div className="profile-page-box">
                                { /* <div className="verification-toolbar">
                                    <h3>Поръчай сега:</h3>
                                    <ul>
                                        <li className="passed-verification">
                                            <div>
                                                <figure>
                                                    <img src="/img/icons/check.png" alt="" />
                                                </figure>
                                                Верификация
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <figure>
                                                    <img src="/img/icons/card.png" alt="" />
                                                </figure>
                                                Заяви карта
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <figure>
                                                    <img src="/img/icons/card.png" alt="" />
                                                </figure>
                                                Поръчай
                                            </div>
                                        </li>
                                    </ul>
                                </div> */ }
                                <div className="row">
                                    <UserHeader />
                                    <div className="col-lg-12">
                                        <div className="infoFigureBox defi-code">
                                            <figure>
                                                <img src="/img/defi/logo.svg" alt="" />
                                            </figure>
                                            <div className="text">
                                                <span>
                                                    Your discount code: <input type="text" defaultValue={this.props.user.defi_code} readOnly ref={defiCode => (this.defiCode = defiCode)} />
                                                </span>
                                                <a title="" onDoubleClick={this.copyToClipboard}> <i className="fa fa-copy" alt="" style={{ color: '#34c6f4' }} /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row equal">
                                            <AccountsBalances />
                                        </div>
                                        {/* <div className="row">
                                            <AssetsWidget />
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {(this.props.user.bitcoin_address && this.props.user.bitcoin_address.trim().length) &&
                                                        <div className="col-lg-6">
                                                            <div className="infoFigureBox">
                                                                <div className="text crypto-numbers">
                                                                    <p>Bitcoin <Translate id="address" /></p>
                                                                    <p className="dark-text">{this.props.user.bitcoin_address}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(this.props.user.bitcoin_cash_address && this.props.user.bitcoin_cash_address.trim().length) &&
                                                        <div className="col-lg-6">
                                                            <div className="infoFigureBox">
                                                                <div className="text crypto-numbers">
                                                                    <p>Bitcoin Cash <Translate id="address" /></p>
                                                                    <p className="dark-text">{this.props.user.bitcoin_cash_address}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(this.props.user.ethereum_address && this.props.user.ethereum_address.trim().length) &&
                                                        <div className="col-lg-6">
                                                            <div className="infoFigureBox">
                                                                <div className="text crypto-numbers">
                                                                    <p>Ethereum <Translate id="address" /></p>
                                                                    <p className="dark-text">{this.props.user.ethereum_address}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-lg-6">
                                                        <div className="infoFigureBox">
                                                            <div className="text crypto-numbers">
                                                                <p><Translate id="toChangeAddresses" /></p>
                                                                <strong><Link to="/settings"><Translate id="settings" /> / <Translate id="general" /></Link></strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <UserSidebar current="profile" />
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth, user }) {
    return { auth, user };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(
        requireAuth(ProfilePage)
    ),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
