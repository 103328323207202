import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";

const AccountActions = (props) => {
    if (!_.isEmpty(props.account)) {
        return (
            <div className="row">

                <div className="col-md-6">
                    <div className="row">
                        {(props.account.type == "USDT" && props.currency.type == "USDT") ? "" : (
                            <div className="col-lg-12">
                                {!props.trade_disabled && (
                                    <Link to={`/accounts/${props.account.type}/buy`} title="" className="btn crypto-button crypto-sell"><Translate id="buy" /></Link>
                                )}
                            </div>
                        )}

                        {!props.trade_disabled && (
                            <div className="col-lg-12">
                                <Link to={`/accounts/${props.account.type}/deposit`} title="" className="btn crypto-button crypto-deposit"><i className="fas fa-arrow-alt-up"></i><Translate id="deposit" /></Link>
                            </div>
                        )}

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        {(props.account.type == "USDT" && props.currency.type == "USDT") ? "" : (
                            <div className="col-lg-12">
                                <Link to={`/accounts/${props.account.type}/sell`} title="" className="btn crypto-button crypto-buy"><Translate id="sell" /></Link>
                            </div>
                        )}
                        <div className="col-lg-12">
                            <Link to={`/accounts/${props.account.type}/withdraw`} title="" className="btn crypto-button crypto-withdraw"><i className="fas fa-arrow-alt-down"></i><Translate id="withdraw" /></Link>
                        </div>
                    </div>
                </div>

                {(props.account.type == "USDT" && props.currency.type == "USDT") ? (
                    <React.Fragment>
                        <div className="col-lg-6 col-md-6 col-sm-12">&nbsp;</div>
                        <div className="col-lg-6 col-md-6 col-sm-12">&nbsp;</div>
                    </React.Fragment>
                ) : ""}
            </div >
        );
    } else {
        return <div />
    }
};

export default AccountActions;
