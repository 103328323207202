import React from 'react';
import Helmet from 'react-helmet';
import { Translate } from "react-localize-redux";

const head = () => {
    return (
        <Helmet>
            <title>{`Оопс 404, Не намерихме тази страница - BoneX | Crypto Exchange`}</title>
            <meta property="og:title" content={`Оопс 404, Не намерихме тази страница - BoneX | Crypto Exchange`} />
        </Helmet>
    );
}

const NotFoundPage = ({ staticContext = {} }) => {
    staticContext.notFound = true;
    return (
        <div>
            {head()}
            <h1><Translate id="notFoundText" /></h1>
        </div>
    )
};

export default {
    component: NotFoundPage
};