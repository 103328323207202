import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { fetchUnseenMessages } from '../../actions';

class UserSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unseenCount: 0
    };
  }


  async componentDidMount() {
    let unseen = await this.props.fetchUnseenMessages();
    if (unseen && unseen.result) {
      this.setState({ unseenCount: unseen.result.length });
    }
  }

  render() {
    return (
      <div className="col-12 col-lg-2 col-md-3 order-cols-left">
        <div className="profile-page-sidebar profile-cols-height">
          <div className="sidebar-box">
            <div className="profile-box">
              <img src="/sample/profile.png" title="Profile" />
            </div>
            <div className="profile-usermenu">
              <span></span>
              <Link className={`inbox-btn ${this.state.unseenCount != 0 ? 'inbox-pulse' : ''}`} to="/messages">
                <Translate id="inbox" />
                {this.state.unseenCount != 0 ? <var>{this.state.unseenCount}</var> : ""}
              </Link>
              <ul className="nav">
                <li className={this.props.current == "profile" ? "active" : ""}>
                  <Link to="/profile">
                    <span className="icon-holder">
                      <i className="fas fa-angle-right"></i>
                    </span>
                    <Translate id="profile" />
                  </Link>
                </li>
                <li
                  className={this.props.current == "accounts" ? "active" : ""}
                >
                  <Link to="/accounts">
                    <span className="icon-holder">
                      <i className="fas fa-angle-right"></i>
                    </span>
                    <Translate id="balance" />
                  </Link>
                </li>
                {this.props.user.verified == 1 ? (
                  // <li
                  //   className={this.props.current == "card" ? "active" : ""}
                  // >
                  //   <Link to="/card">
                  //     <span className="icon-holder">
                  //       <i className="fas fa-angle-right"></i>
                  //     </span>
                  //     <Translate id="card" />
                  //   </Link>
                  // </li>
                  <li></li>
                ) : (
                  <li></li>
                )}
                <li
                  className={
                    this.props.current == "orders" ? "active" : ""
                  }
                >
                  <Link to="/orders">
                    <span className="icon-holder">
                      <i className="fas fa-angle-right"></i>
                    </span>
                    <Translate id="orders" />
                  </Link>
                </li>
                {this.props.user.verified == 1 ? (
                  <li
                    className={this.props.current == "bank" ? "active" : ""}
                  >
                    <Link to="/bank">
                      <span className="icon-holder">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <Translate id="banking" />
                    </Link>
                  </li>
                ) : (
                  <li></li>
                )}
                <li
                  className={
                    this.props.current == "transactions" ? "active" : ""
                  }
                >
                  <Link to="/transactions">
                    <span className="icon-holder">
                      <i className="fas fa-angle-right"></i>
                    </span>
                    <Translate id="transactions" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.current == "affiliate" ? "active" : ""
                  }
                >
                  <Link to="/affiliate">
                    <span className="icon-holder">
                      <i className="fas fa-angle-right"></i>
                    </span>
                    <Translate id="affiliateSidebarLink" />
                  </Link>
                </li>
                <li
                  className={
                    this.props.current == "bonus" ? "active" : ""
                  }
                >
                  <Link to="/bonus">
                    <span className="icon-holder">
                      <i className="fas fa-angle-right"></i>
                    </span>
                    <Translate id="bonus" />
                  </Link>
                </li>
                <li
                  className={this.props.current == "settings" ? "active" : ""}
                >
                  <Link to="/settings">
                    <span className="icon-holder">
                      <i className="fas fa-angle-right"></i>
                    </span>
                    <Translate id="settings" />
                  </Link>
                </li>
                {this.props.user.verified != 1 ? (
                  <li
                    className={
                      this.props.current == "verification" ? "active" : ""
                    }
                  >
                    <Link to="/verification">
                      <span className="icon-holder">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <Translate id="verification" />
                    </Link>
                  </li>
                ) : (
                  <li></li>
                )}
                <li className="logout-btn">
                  <a href="/logout">
                    <span className="icon-holder">
                      <i className="fal fa-sign-out"></i>
                    </span>
                    <Translate id="signout" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, user }) {
  return { auth, user };
}

export default connect(mapStateToProps, { fetchUnseenMessages })(UserSidebar);
