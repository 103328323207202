import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from "react-localize-redux";
import { fetchOrders, cancelOrder } from '../../actions';

class Transactions extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchOrders();
  }

  componentDidUpdate() {
    const dataEvent = document.createEvent('HTMLEvents');
    dataEvent.initEvent('datatable-data', true, true);
    document.dispatchEvent(dataEvent);
  }

  async onClickCancel(orderId) {
    let msg = 'Сигурни ли сте, че желаете да откажете поръчката?';
    if (this.props.activeLanguage.code == 'en') {
      msg = 'Are you sure that you wish to cancel the order?';
    } else if (this.props.activeLanguage.code == 'ro') {
      msg = 'Sunteți siguri că doriți să renunțați la comanda dvs.?';
    } else if (this.props.activeLanguage.code == 'gr') {
      msg = 'Είστε σίγουροι, ότι θέλετε να ακυρώσετε την παραγγελία?';
    }
    if (!confirm(msg)) {
      return;
    }

    let action = await this.props.cancelOrder(orderId);
    if (action.success) {
      this.props.fetchOrders();
    }
  }

  rows() {
    return _.map(this.props.orders, (order, number) => {
      return (
        <tr key={order.id}>
          <td align="center">
            {number * 1 + 1}
          </td>
          <td align="center">
            {order.reference}
          </td>
          <td align="center" name="action">{order.action}</td>
          <td>
            <strong className="amount" name="amount">{parseFloat(order.amount).toFixed(order.account_decimals)} {order.account_type}</strong>
          </td>
          <td>
            <strong className="total" name="total">{order.total} {order.currency}</strong>
          </td>
          <td>
            <strong className="rate" name="rate">{order.rate} {order.currency}</strong>
          </td>
          <td>
            <strong className="status" name="status">{order.status}</strong>
          </td>
          <td>
            <strong className="action">
              {(order.status == 'PENDING') ? (
                <button className="btn btn-action" style={{ backgroundColor: '#fc2121' }} onClick={() => this.onClickCancel(order.id)}><Translate id="cancel" /></button>
              ) : ""}
            </strong>
          </td>
          <td>
            <time>
              {new Intl.DateTimeFormat(this.props.translate("lang"), {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(order._created))}
            </time>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="white-box no-pad">
          <div className="table-box">
            <h4 className="form-section-title">
              <Translate id="orders" />
              <i className="sprite transfers-icon"></i>
            </h4>
            <div style={{ padding: '10px' }}>
              <div className="tableFilter row">
                <div className="col-md-4">
                  <div className="form-group">
                    <select className="form-control select" id="action" name="action" placeholder="Действие" data-column-index="2">
                      <option>All</option>
                      <option value="BUY">BUY</option>
                      <option value="SELL">SELL</option>
                      <option value="DEPOSIT">DEPOSIT</option>
                      <option value="WITHDRAW">WITHDRAW</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <select className="form-control select" id="status" name="status" data-column-index="6">
                      <option>All</option>
                      <option value="PENDING">PENDING</option>
                      <option value="SUCCESS">SUCCESS</option>
                      <option value="CANCELED">CANCELED</option>
                      <option value="FAILED">FAILED</option>
                    </select>
                  </div>
                </div>
              </div>
              <table className="custom-table orders-table" style={{ width: '100%', overflow: 'scroll' }}>
                <thead>
                  <tr>
                    <th align="center">
                      <strong>#</strong>
                    </th>
                    <th align="center">
                      <strong><Translate id="referenceNumber" /></strong>
                    </th>
                    <th align="center">
                      <strong><Translate id="action" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="amount" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="price" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="rate" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="status" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="action" /></strong>
                    </th>
                    <th>
                      <strong><Translate id="date" /></strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.rows()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div >
    );
  }
};


function mapStateToProps({ orders }) {
  return { orders };
}

export default connect(mapStateToProps, { fetchOrders, cancelOrder })(withLocalize(Transactions));