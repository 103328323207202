import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import AssetLightWidget from "./AssetLightWidget";
import { formatMoney } from "../../utilities/Format";

class AssetsWidget extends Component {
  render() {
    return (
      <div className="col-md-12">
        <div className="infoFigureBox text-center">
          {_.map(this.props.rates, rate => {
            if (
              rate.type == "BTC" ||
              rate.type == "ETH" ||
              rate.type == "BCH" ||
              rate.type == "XRP" ||
              rate.type == "RVN" ||
              rate.type == "UNI" ||
              rate.type == "LINK" ||
              rate.type == "AAVE" ||
              rate.type == "USDT"
            ) {
              return (
                <div className="col-md-6" key={rate.type}>
                  <div className="text" style={{ display: "block" }}>
                    <strong>
                      {rate.type}:{" "}
                      {formatMoney(rate.rate / this.props.currency.rate)}{" "}
                      <Translate id={this.props.currency.symbol} />
                    </strong>
                  </div>
                  <AssetLightWidget {...rate} key={rate.id} />
                  <br />
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ rates, currency }) {
  return { rates, currency };
}

export default connect(mapStateToProps)(AssetsWidget);
