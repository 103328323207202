import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate, withLocalize } from "react-localize-redux";
import { fetchUser, updatePrivacySettings } from '../../actions';

class PrivacySettingsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            lang: this.props.activeLanguage.code
        };
    }

    onClick(event) {
        event.preventDefault();

        if (this.state.lang == 'bg') {
            if (!confirm("Моля потвърдете искането!")) {
                return false;
            }
        } else {
            if (!confirm("Please confirm your request!")) {
                return false;
            }
        }

        this.props.updatePrivacySettings({
            action: event.target.value
        }).then(() => {
            this.setState({
                submitted: true
            });
        });
    }

    render() {
        if (this.state.submitted) {
            return (
                <div className="infoFigureBox">
                    <div className="row">
                        <div className="col-12">
                            <header className="element-section-title has-bgr-element small-element-section-title text-center crypto-sell-color">
                                <h3><Translate id="successLabel" />!</h3>
                            </header>
                        </div>
                        <div className="col-md-offset-2 col-md-8">
                            <div className="success-msg-box">
                                <figure><i className="far fa-check-circle"></i></figure>
                                <h5><Translate id="success.privacy" /></h5>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="infoFigureBox">
                    <div className="row">
                        <div className="col-md-12 element-section-title has-bgr-element text-center crypto-sell-color" style={{ padding: '0 50px 46px' }}>
                            <h3><Translate id="privacyPolicy" /></h3>
                        </div>
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <p><Translate id="privacy.headInfo" /> <Link to="/privacy"><Translate id="privacyPolicy" /></Link> <Translate id="and" /> <Translate id="our" /> <Link to="/terms"><Translate id="cookiesPolicy" /></Link>.</p>
                                <hr />
                            </div>
                            <div className="col-md-12">
                                <h4><Translate id="privacy.data" /></h4>
                                <p><Translate id="privacy.dataInfo" /></p>
                                <button
                                    onClick={this.onClick.bind(this)}
                                    value="data"
                                    className="btn btn-action"
                                    style={{ margin: '0px', whiteSpace: 'normal' }}
                                ><Translate id="privacy.data" /></button>
                                <hr />
                            </div>
                            <div className="col-md-12">
                                <h4><Translate id="privacy.deletion" /></h4>
                                <p><Translate id="privacy.deletionInfo" /></p>
                                <button
                                    onClick={this.onClick.bind(this)}
                                    value="delete"
                                    className="btn btn-action"
                                    style={{ margin: '0px' }}
                                ><Translate id="privacy.deletion" /></button>
                                <hr />
                            </div>
                            <div className="col-md-12">
                                <h4><Translate id="privacy.correction" /></h4>
                                <p><Translate id="privacy.correctionInfo" /></p>
                                <button
                                    onClick={this.onClick.bind(this)}
                                    value="correction"
                                    className="btn btn-action"
                                    style={{ margin: '0px' }}
                                ><Translate id="privacy.correction" /></button>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
};

function mapStateToProps({ user }) {
    return { user };
}

export default connect(mapStateToProps, { fetchUser, updatePrivacySettings })(withLocalize(PrivacySettingsForm))
