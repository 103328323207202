import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { fetchUser } from '../../actions';
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebar from '../../components/users/UserSidebar';
// import AccountsBalances from '../../components/accounts/AccountsBalances';
import UserHeader from '../../components/users/UserHeader';
import TransactionsTable from '../../components/transactions/TransactionsTable';
import UserSidebarNew from '../../components/users/UserSidebarNew';
import TransactionsTableNew from '../../components/transactions/TransactionsTableNew';
import { Translate } from 'react-localize-redux';

class TransactionsPageNew extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Транзакции - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Транзакции - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidMount() {
        if (typeof window != 'undefined') {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return (
            <div className="container">
                <div className="">
                    {this.head()}
                    <UserSidebarNew current="transactions" />
                    <div className="">
                        <div className="profile-page-new-wrapper">
                            <div className="affiliate-box">
                                <div className="">
                                    <h1><Translate id="transactions" /></h1>
                                    <p style={{ color: 'white' }}><Translate id="transactionsHistory" /></p>
                                    <TransactionsTableNew />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth }) {
    return { auth };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(
        requireAuth(TransactionsPageNew)
    ),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
