import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Translate } from "react-localize-redux";
import { fetchUser } from '../../actions';
import requireAuth from '../../components/hocs/requireAuth';
import UserSidebar from '../../components/users/UserSidebar';
import GeneralSettingsForm from '../../components/settings/GeneralSettingsForm';
import SecuritySettingsForm from '../../components/settings/SecuritySettingsForm';
import PasswordSettingsFormNew from '../../components/settings/PasswordSettingsFormNew';
import PrivacySettingsFormNew from '../../components/settings/PrivacySettingsFormNew';
import UserHeader from '../../components/users/UserHeader';
import UserSidebarNew from '../../components/users/UserSidebarNew';
import SecuritySettingsFormNew from '../../components/settings/SecuritySettingsFormNew';

class SettingsPageNew extends Component {
    head() {
        return (
            <Helmet>
                <title>{`Настройки - BoneX | Crypto Exchange`}</title>
                <meta property="og:title" content={`Настройки - BoneX | Crypto Exchange`} />
            </Helmet>
        );
    }

    componentDidMount() {
        this.props.fetchUser();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.head()}
                    <UserSidebarNew current="settings" />
                    <div className="">
                        <div className="">
                            <div className="profile-page-box">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="" >
                                            <div className="profile-page-new-wrapper settings-section-wrapper">
                                                <div className='affiliate-box'>
                                                    <h1><Translate id="updatePasswordTitle" /></h1>
                                                    <p><Translate id="updatePassword" /></p>
                                                    <div className="tab-pane fade in" id="password">
                                                        <PasswordSettingsFormNew></PasswordSettingsFormNew>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="" >
                                            <div className="profile-page-new-wrapper settings-section-wrapper">
                                                <div className='affiliate-box'>
                                                    <h1>2FA</h1>
                                                    <p>Set up Two-Factor authentication (2FA)</p>
                                                    <div className="tab-pane fade in active" id="security">
                                                        <SecuritySettingsFormNew />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="" >
                                            <div className="profile-page-new-wrapper settings-section-wrapper">
                                                <div className='affiliate-box'>
                                                    <div className="tab-pane fade in" id="privacy">
                                                        <PrivacySettingsFormNew />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps({ auth }) {
    return { auth };
}

export default {
    component: connect(mapStateToProps, { fetchUser })(
        requireAuth(SettingsPageNew)
    ),
    loadData: ({ dispatch }) => dispatch(fetchUser())
}
